import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constant/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainBox: {
            width: '100%',
            backgroundColor: COLORS.LIGHT_BLUE,
            paddingBottom: 48,
        },
        darkPurpleBanner: {
            width: '100%',
            backgroundColor: COLORS.DARK_PURPLE,
            color: '#fff',
            paddingTop: 24,
            paddingBottom: 24,
        },
        lightOrangeBanner: {
            width: '100%',
            backgroundColor: COLORS.LIGHT_ORANGE,
            color: COLORS.DARK_GREY,
            paddingTop: 12,
            paddingBottom: 12,
            textAlign: 'center',
        },
        bannerContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
            },
        },
        midBlueBox: {
            width: '100%',
            backgroundColor: COLORS.MIDIUM_BLUE,
            paddingTop: 90,
            paddingBottom: 90,
            color: COLORS.DARK_GREY,
        },
        lightBlueBox: {
            width: '100%',
            backgroundColor: COLORS.LIGHT_BLUE,
            paddingTop: 60,
            paddingBottom: 60,
            color: COLORS.DARK_GREY,
            textAlign: 'center',
        },
        darkBlueBox: {
            width: '100%',
            backgroundColor: COLORS.DARK_BLUE,
            paddingTop: 90,
            paddingBottom: 90,
            color: '#fff',
            textAlign: 'left',
        },
        headerContentContainer: {
            paddingTop: 160,
            color: '#fff',
        },
        darkGrey: {
            color: COLORS.DARK_GREY,
        },
        fullWidth: {
            width: '100%',
        },
        whiteButton: {
            fontSize: 16,
            paddingTop: 6,
            paddingBottom: 6,
            fontFamily: 'Poppins',
            color: COLORS.DARK_PURPLE,
            backgroundColor: '#fff',
            paddingLeft: 12,
            paddingRight: 12,
            borderStyle: 'none',
            letterSpacing: 2,
            fontWeight: 'bold',
            cursor: 'pointer',
            textAlign: 'center',
            [theme.breakpoints.down('xs')]: {
                marginTop: 12,
            },
            borderRadius: 0,
            '&:hover': {
                backgroundColor: COLORS.WHITE_BORDER_HOVER,
            },
        },
        whiteButtonXsFullWidth: {
            fontSize: 16,
            paddingTop: 6,
            paddingBottom: 6,
            fontFamily: 'Poppins',
            color: COLORS.DARK_PURPLE,
            backgroundColor: '#fff',
            paddingLeft: 12,
            paddingRight: 12,
            borderStyle: 'none',
            letterSpacing: 2,
            fontWeight: 'bold',
            cursor: 'pointer',
            textAlign: 'center',
            [theme.breakpoints.down('xs')]: {
                alignSelf: 'center',
                width: '100%',
            },
            borderRadius: 0,
            '&:hover': {
                backgroundColor: COLORS.WHITE_BORDER_HOVER,
            },
        },
        whiteButtonFullWidth: {
            fontSize: 16,
            paddingTop: 6,
            paddingBottom: 6,
            fontFamily: 'Poppins',
            color: COLORS.DARK_PURPLE,
            backgroundColor: '#fff',
            paddingLeft: 12,
            paddingRight: 12,
            borderStyle: 'none',
            letterSpacing: 2,
            fontWeight: 'bold',
            cursor: 'pointer',
            textAlign: 'center',
            alignSelf: 'center',
            width: '95%',
            borderRadius: 0,
            '&:hover': {
                backgroundColor: COLORS.WHITE_BORDER_HOVER,
            },
        },
        purpleButton: {
            fontSize: 16,
            paddingTop: 6,
            paddingBottom: 6,
            fontFamily: 'Poppins',
            color: '#fff',
            backgroundColor: COLORS.DARK_PURPLE,
            paddingLeft: 12,
            paddingRight: 12,
            borderStyle: 'none',
            letterSpacing: 2,
            fontWeight: 'bold',
            cursor: 'pointer',
            textAlign: 'center',
            [theme.breakpoints.down('xs')]: {
                alignSelf: 'center',
                width: '100%',
            },
            borderRadius: 0,
            '&:hover': {
                backgroundColor: COLORS.DARK_PURPLE_HOVER,
            },
        },
        purpleButtonFullWidth: {
            fontSize: 16,
            paddingTop: 6,
            paddingBottom: 6,
            fontFamily: 'Poppins',
            color: '#fff',
            backgroundColor: COLORS.DARK_PURPLE,
            paddingLeft: 12,
            paddingRight: 12,
            borderStyle: 'none',
            letterSpacing: 2,
            fontWeight: 'bold',
            cursor: 'pointer',
            textAlign: 'center',
            alignSelf: 'center',
            width: '100%',
            borderRadius: 0,
            '&:hover': {
                backgroundColor: COLORS.DARK_PURPLE_HOVER,
            },
        },
        marginTopMini: {
            marginTop: 12,
        },
        marginTopMid: {
            marginTop: 36,
        },
        marginTopLarge: {
            marginTop: 60,
        },
        darkPurple: {
            color: COLORS.DARK_PURPLE,
        },
        instructorMessageContainer: {
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column-reverse',
            },
        },
        instructorPointContainer: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 8,
            alignItems: 'center',
        },
        pointContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            [theme.breakpoints.down('xs')]: {
                marginTop: 8,
            },
        },
        instructorPointIcon: {
            marginRight: 6,
        },
        headerImage: {
            width: '100%',
            minHeight: 600,
            backgroundImage: `url(/assets/workshopHeaderImage.webp)`,
            backgroundPosition: 'right',
            backgroundSize: 'cover',
            color: '#fff',
            textAlign: 'left',
        },
        bannerIcon: {
            fill: '#fff',
            marginRight: 4,
            height: 24,
            width: 24,
        },
        paperIcon: {
            fill: COLORS.DARK_GREY,
            marginRight: 4,
            height: 24,
            width: 24,
        },
        upcomingPaper: {
            padding: 12,
            color: COLORS.DARK_GREY,
            textAlign: 'left',
            borderRadius: 12,
        },
        upcomingContainer: {
            padding: 12,
            [theme.breakpoints.up('md')]: {
                padding: 36,
                paddingLeft: 60,
                paddingRight: 60,
            },
        },
        darkPurpleBox: {
            width: '100%',
            backgroundColor: COLORS.DARK_PURPLE,
            color: '#fff',
            paddingTop: 60,
            paddingBottom: 60,
            textAlign: 'center',
        },
        textField: {
            textAlign: 'center',
            width: '95%',
            alignSelf: 'center',
            marginTop: 16,
            color: '#fff',
            borderColor: '#fff',
        },
        outlinedInput: {
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#fff',
                color: '#fff',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#fff',
                color: '#fff',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#fff',
                color: '#fff',
            },
            '& input[type=number]': {
                '-moz-appearance': 'textfield',
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            borderColor: '#fff',
            color: '#fff',
        },
        fieldLabel: {
            color: '#fff',
            '&$focuse': {
                color: '#fff',
            },
            '&.Mui-focused': {
                color: '#fff',
            },
        },
        textFieldDark: {
            textAlign: 'center',
            width: '100%',
            alignSelf: 'center',
            marginTop: 16,
            color: COLORS.DARK_GREY,
            borderColor: COLORS.DARK_GREY,
        },
        outlinedInputDark: {
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: COLORS.DARK_GREY,
                color: COLORS.DARK_GREY,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: COLORS.DARK_GREY,
                color: COLORS.DARK_GREY,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: COLORS.DARK_GREY,
                color: COLORS.DARK_GREY,
            },
            borderColor: COLORS.DARK_GREY,
            color: COLORS.DARK_GREY,
        },
        fieldLabelDark: {
            color: COLORS.DARK_GREY,
            '&$focuse': {
                color: COLORS.DARK_GREY,
            },
            '&.Mui-focused': {
                color: COLORS.DARK_GREY,
            },
        },
        secureMySpotIcon: {
            width: 100,
            height: 'auto',
        },
    }),
);
