export const validateEmail = (input: string): boolean => {
    const validRegex =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (input.toLowerCase().match(validRegex)) {
        return true;
    } else {
        return false;
    }
};

export const validatePhoneNumber = (input: string): boolean => {
    if (input.length > 9) {
        return true;
    } else {
        return false;
    }
};
