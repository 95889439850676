import { observer } from 'mobx-react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AppStore } from '../stores/appStore';
import { PATH } from './path';
import { lazy, Suspense } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { assessments } from '../constant/assessment';
import { WORKSHOP_SEM_PAGES } from '../pages/workshop';

const HomePage = lazy(() => import('../pages/home'));
const ProgramPage = lazy(() => import('../pages/program'));
const ProgramDetailPage = lazy(() => import('../pages/programDetailsV2'));
const Apply = lazy(() => import('../pages/apply'));
const WorkshopSurvey = lazy(() => import('../pages/workshopSurvey'));
const About = lazy(() => import('../pages/aboutV2'));
const Faq = lazy(() => import('../pages/faq'));
const Page404 = lazy(() => import('../pages/404'));
const WorkshopAttendance = lazy(() => import('../pages/workshopAttendance'));
const WorkshopPage = lazy(() => import('../pages/workshop'));
const WorkshopPageV2 = lazy(() => import('../pages/workshopV2'));
const WorkshopSuccessPage = lazy(() => import('../pages/workshopSuccess'));
const PaymentSuccessPage = lazy(() => import('../pages/paymentSuccess'));
const WorkshopLandingPage = lazy(() => import('../pages/workshopLanding'));
const QuizLandingPage = lazy(() => import('../pages/quizLanding'));
const ProgramLandingPage = lazy(() => import('../pages/programLanding'));
const AssessmentPage = lazy(() => import('../pages/assessment'));
const StrengthAssessmentPage = lazy(() => import('../pages/strengthAssessment'));
const ValueAssessmentPage = lazy(() => import('../pages/valueAssessment'));

interface RoutesProps {
    appStore: AppStore;
}

const Routes: React.FC<RoutesProps> = observer((props: RoutesProps) => {
    return (
        <Router>
            <Suspense
                fallback={
                    <Backdrop
                        open={true}
                        style={{
                            zIndex: 100,
                        }}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
            >
                <Switch>
                    <Route exact path={PATH.HOME} component={() => <HomePage appStore={props.appStore} />} />
                    <Route exact path={PATH.PROGRAM} component={() => <ProgramPage appStore={props.appStore} />} />
                    <Route
                        exact
                        path={PATH.PROGRAMS}
                        component={() => <ProgramDetailPage appStore={props.appStore} />}
                    />
                    <Route exact path={PATH.PROGRAM_APPLY} component={() => <Apply appStore={props.appStore} />} />
                    <Route
                        exact
                        path={PATH.WORKSHOP_SURVEY}
                        component={() => <WorkshopSurvey appStore={props.appStore} />}
                    />
                    <Route exact path={PATH.ABOUT} component={() => <About appStore={props.appStore} />} />
                    <Route exact path={PATH.FAQ} component={() => <Faq appStore={props.appStore} />} />
                    <Route exact path={PATH.NOT_FOUND} component={() => <Page404 />} />
                    {assessments.map((assessment) => {
                        return (
                            <Route
                                key={`assessment-${assessment.slug}`}
                                exact
                                path={PATH.ASSESSMENT.replace(':slug', assessment.slug)}
                                component={() => <AssessmentPage assessment={assessment} appStore={props.appStore} />}
                            />
                        );
                    })}
                    {/* SEM ASSESSMENT PAGES */}
                    {assessments.map((assessment) => {
                        if (assessment.sem && assessment.sem.length > 0) {
                            return assessment.sem.map((sem) => {
                                return (
                                    <Route
                                        key={`assessment-sem-${assessment.slug}-${sem.semSlug}`}
                                        exact
                                        path={`${PATH.ASSESSMENT.replace(':slug', assessment.slug)}/${sem.semSlug}`}
                                        component={() => (
                                            <AssessmentPage
                                                assessment={assessment}
                                                appStore={props.appStore}
                                                semHeader={sem.assessmentHeader}
                                            />
                                        )}
                                    />
                                );
                            });
                        }
                    })}
                    <Route
                        exact
                        path={PATH.STRENGTH_ASSESSMENT}
                        component={() => <StrengthAssessmentPage appStore={props.appStore} />}
                    />
                    <Route
                        exact
                        path={PATH.VALUE_ASSESSMENT}
                        component={() => <ValueAssessmentPage appStore={props.appStore} />}
                    />
                    {/* SEM PAGES */}
                    {WORKSHOP_SEM_PAGES.map((workshopPage) => {
                        return (
                            <Route
                                key={`workshop=slug-${workshopPage.slug}`}
                                exact
                                path={PATH.WORKSHOP + `/${workshopPage.slug}`}
                                component={() => (
                                    <WorkshopPage appStore={props.appStore} content={{ ...workshopPage }} />
                                )}
                            />
                        );
                    })}
                    <Route
                        exact
                        path={PATH.LANDING_WORKSHOP}
                        component={() => <WorkshopLandingPage appStore={props.appStore} />}
                    />
                    <Route exact path={PATH.LANDING_QUIZ} component={() => <QuizLandingPage />} />
                    <Route
                        exact
                        path={PATH.LANDING_PROGRAM}
                        component={() => <ProgramLandingPage appStore={props.appStore} />}
                    />
                    <Route
                        exact
                        path={PATH.WORKSHOP}
                        component={() => <WorkshopPage appStore={props.appStore} content={{}} />}
                    />
                    <Route
                        exact
                        path={PATH.WORKSHOP_V2}
                        component={() => <WorkshopPageV2 appStore={props.appStore} />}
                    />
                    <Route
                        exact
                        path={PATH.WORKSHOP_SUCCESS}
                        component={() => <WorkshopSuccessPage appStore={props.appStore} />}
                    />
                    <Route
                        exact
                        path={PATH.PAYMENT_SUCCESS}
                        component={() => <PaymentSuccessPage appStore={props.appStore} />}
                    />
                    <Route
                        exact
                        path={PATH.WORKSHOP_ATTENTANCE}
                        component={() => <WorkshopAttendance appStore={props.appStore} />}
                    />
                    <Route exact path="*" component={() => <Page404 />} />
                </Switch>
            </Suspense>
        </Router>
    );
});

export default Routes;
