import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constant/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        purpleContainer: {
            backgroundColor: COLORS.DARK_PURPLE,
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            padding: 24,
            paddingLeft: 12,
            paddingRight: 12,
            marginTop: 32,
        },
        marginTopMid: {
            marginTop: 36,
        },
        pointContainer: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 6,
            [theme.breakpoints.down('xs')]: {
                marginTop: 8,
            },
        },
        marginTopMini: {
            marginTop: 12,
        },
        bannerIcon: {
            fill: '#fff',
            marginRight: 4,
            height: 20,
            width: 20,
        },
        secureMySpotIcon: {
            width: 100,
            height: 'auto',
        },
        pointText: {},
        textField: {
            textAlign: 'center',
            width: '80%',
            [theme.breakpoints.down('xs')]: {
                width: '95%',
            },
            alignSelf: 'center',
            marginTop: 16,
            color: COLORS.DARK_GREY,
            borderColor: COLORS.DARK_GREY,
        },
        mobileHide: {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        outlinedInput: {
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: COLORS.DARK_GREY,
                color: COLORS.DARK_GREY,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: COLORS.DARK_GREY,
                color: COLORS.DARK_GREY,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: COLORS.DARK_GREY,
                color: COLORS.DARK_GREY,
            },
            '& input[type=number]': {
                '-moz-appearance': 'textfield',
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            borderColor: COLORS.DARK_GREY,
            color: COLORS.DARK_GREY,
        },
        fieldLabel: {
            color: COLORS.DARK_GREY,
            '&$focuse': {
                color: COLORS.DARK_GREY,
            },
            '&.Mui-focused': {
                color: COLORS.DARK_GREY,
            },
        },
        purpleButtonFullWidth: {
            fontSize: 16,
            paddingTop: 6,
            paddingBottom: 6,
            fontFamily: 'Poppins',
            color: '#fff',
            backgroundColor: COLORS.DARK_PURPLE,
            paddingLeft: 12,
            paddingRight: 12,
            borderStyle: 'none',
            letterSpacing: 2,
            fontWeight: 'bold',
            cursor: 'pointer',
            textAlign: 'center',
            alignSelf: 'center',
            width: '100%',
            borderRadius: 0,
            '&:hover': {
                backgroundColor: COLORS.DARK_PURPLE_HOVER,
            },
        },
        fullWidth: {
            width: '100%',
        },
        registerFormContainer: {
            textAlign: 'center',
            color: COLORS.DARK_GREY,
            marginTop: 48,
        },
    }),
);
