import { makeStyles } from '@material-ui/styles';
import { COLORS } from '../../constant/colors';

export const useStyles = makeStyles({
    whiteBox: {
        width: '100%',
        paddingBottom: '100px',
        color: COLORS.DARK_GREY,
        textAlign: 'center',
        paddingTop: '36px',
        backgroundColor: COLORS.TRANSPARENT,
    },
    footerSocialMediaContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '16px',
    },
    footerSocialMediaIcon: {
        margin: '8px',
    },
    footerLinkContainer: {
        marginBottom: '16px',
    },
    footerLink: {
        color: COLORS.DARK_BLUE,
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    textDecorationNone: {
        textDecoration: 'none',
    },
    footerSocialMediaButton: {
        minWidth: '36px',
        padding: '0px',
        '&:hover': {
            backgroundColor: COLORS.TRANSPARENT_LIGHT_HOVER,
        },
    },
});
