import { action, computed, makeObservable, observable } from 'mobx';
import { DOMAIN_STAGE_MAP, STAGE_DEFAULT_PROGRAM_MAP } from '../config/web';
import { AssessmentStore } from './assessmentStore';
import { ProgramStore } from './programStore';

export class AppStore {
    @observable loading: number;
    @observable programStore: ProgramStore | null;
    @observable assessmentStore: AssessmentStore | null;
    @observable video: {
        url: string;
        play: boolean;
    };
    currentStage: string;

    constructor() {
        this.currentStage = DOMAIN_STAGE_MAP[window.location.hostname];
        this.loading = 0;
        this.programStore = null;
        this.assessmentStore = null;
        this.video = {
            url: '',
            play: false,
        };
        this.initProgramStore();
        makeObservable(this);
    }

    initProgramStore = (): void => {
        this.programStore = new ProgramStore();
        this.assessmentStore = new AssessmentStore();
    };

    @action
    playVideo(videoUrl: string): void {
        this.video = {
            url: videoUrl,
            play: true,
        };
    }

    @action
    stopVideo(): void {
        this.video = {
            url: '',
            play: true,
        };
    }

    @computed
    get programId(): string {
        if (!this.programStore || !this.programStore.program || !this.programStore.program.programId) {
            return STAGE_DEFAULT_PROGRAM_MAP[this.currentStage];
        }
        return this.programStore.program.programId;
    }
}
