export const COLORS = {
    LIGHT_BLUE: '#F2F4FB',
    MIDIUM_BLUE: '#B8C1D9',
    MIDIUM_GREY: '#6C7275',
    DARK_GREY: '#393E42',
    DARK_BLUE: '#425880',
    OFF_WHITE: '#F4F5F1',
    DARK_PURPLE: '#392744',
    TRANSPARENT: '#00000000',
    LIGHT_ORANGE: '#EDDED0',
    DARK_ORANGE: '#BA5939',
    DARK_PURPLE_HOVER: 'rgba(57, 39, 68, 0.8)',
    DARK_BLUE_HOVER: 'rgba(66, 88, 128, 0.8)',
    WHITE_HOVER: 'rgba(255, 255, 255, 0.8)',
    DARK_BLUE_BORDER_HOVER: 'rgba(184, 193, 217, 0.5)',
    WHITE_BORDER_HOVER: 'rgba(255, 255, 255, 0.5)',
    TRANSPARENT_LIGHT_HOVER: 'rgba(57, 62, 66, 0.2)',
    TRANSPARENT_DARK_HOVER: 'rgba(255, 255, 255, 0.2)',
    LIGHT_YELLOW: '#ECD2AE',
    BACKGROUND_GREY: '#F9FAFB',
    DARK_YELLOW: '#9D6B27',
    DISABLED: 'rgba(108, 114, 117, 0.7)',
};
