import { Program, ProgramPrice, ProgramSession } from '../models/program';
import { format } from 'date-fns';
import PROGRAM_TAGS from '../constant/program';

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const weekdayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const getProgramDateRangeString = (program: Program | null): string => {
    if (program && program.sessions && program.sessions.length > 0) {
        const sortedSessions = program.sessions.slice().sort((s1, s2) => s1.startTime - s2.startTime);
        const fromDate = new Date(sortedSessions[0].startTime);
        const toDate = new Date(sortedSessions[sortedSessions.length - 1].startTime);
        return `${weekdayNames[fromDate.getDay()]} ${monthNames[fromDate.getMonth()]} ${fromDate.getDate()} - ${
            weekdayNames[toDate.getDay()]
        } ${monthNames[toDate.getMonth()]} ${toDate.getDate()}, ${toDate.getFullYear()}`;
    }
    return '-';
};

export const getShortProgramDateRangeString = (program: Program | null): string => {
    if (program && program.sessions && program.sessions.length > 0) {
        const sortedSessions = program.sessions.slice().sort((s1, s2) => s1.startTime - s2.startTime);
        const fromDate = new Date(sortedSessions[0].startTime);
        const toDate = new Date(sortedSessions[sortedSessions.length - 1].startTime);
        return `${monthNames[fromDate.getMonth()]} ${fromDate.getDate()} - ${
            monthNames[toDate.getMonth()]
        } ${toDate.getDate()}, ${toDate.getFullYear()}`;
    }
    return '-';
};

export const getProgramWeekdays = (program: Program | null): string => {
    if (program && program.sessions && program.sessions.length > 0) {
        const sortedSessions = program.sessions.slice().sort((s1, s2) => s1.startTime - s2.startTime);
        const fromDate = new Date(sortedSessions[0].startTime);
        const toDate = new Date(sortedSessions[sortedSessions.length - 1].startTime);
        return `${weekdayNames[fromDate.getDay()]} & ${weekdayNames[toDate.getDay()]}`;
    }
    return '-';
};

export const getShortLocalTimeZoneString = (): string => {
    return new Date()
        .toLocaleTimeString('en-us', {
            timeZoneName: 'short',
        })
        .split(' ')[2];
};

export const getLocalTimeZoneString = (): string => {
    return `${
        new Date()
            .toLocaleTimeString('en-us', {
                timeZoneName: 'short',
            })
            .split(' ')[2]
    }/${Intl.DateTimeFormat().resolvedOptions().timeZone.split('/')[1]}`;
};

export const getLongLocalTimeZoneName = (): string => {
    return new Date()
        .toLocaleTimeString('en-us', {
            timeZoneName: 'long',
        })
        .split(' ')[2];
};

export const getSessionDateDisplay = (session: ProgramSession): string => {
    const date = new Date(session.startTime);
    return `${weekdayNames[date.getDay()]} ${monthNames[date.getMonth()]} ${date.getDate()}`;
};

export const getSessionTimeDisplay = (session: ProgramSession): string => {
    const date = new Date(session.startTime);
    const endDate = new Date(session.startTime + session.length * 1000);
    return `${format(date, 'h:mm')}-${format(endDate, 'h:mmaaa')}`;
};

export const sortProgramSessions = (program: Program | null): void => {
    if (program && program.sessions && program.sessions.length > 1) {
        program.sessions.sort((s1, s2) => s1.startTime - s2.startTime);
    }
};

export const getTimestampDateDisplay = (timestamp: number): string => {
    const date = new Date(timestamp);
    return format(date, 'MMM d, yyyy');
};

export const getWorkshopDateDisplay = (timestamp: number): string => {
    const date = new Date(timestamp);
    return format(date, 'MMM d, yyyy');
};

export const getWorkshopTimeDisplay = (timestamp: number): string => {
    const date = new Date(timestamp);
    return `${format(date, 'h:mmaaa').replace(':00', '')} ${getLongLocalTimeZoneName()} Time`;
};

export const getWorkshopTimeRangeDisplay = (timestamp: number, length: number): string => {
    const date = new Date(timestamp);
    const toDate = new Date(timestamp + length);
    return `${format(date, 'h:mm').replace(':00', '')}-${format(toDate, 'h:mmaaa').replace(
        ':00',
        '',
    )} ${getLongLocalTimeZoneName()} Time`;
};

export const getWorkshopDateTimeDisplay = (timestamp: number): string => {
    const date = new Date(timestamp);
    return `${format(date, 'MMM d, yyyy h:mmaaa').replace(':00', '')} ${getLongLocalTimeZoneName()} Time`;
};

export const isPurchasableProgram = (program: Program | null): boolean => {
    if (program && program.tags && program.tags.length > 0 && program.tags.includes(PROGRAM_TAGS.WORKSHOP)) {
        return true;
    }
    if (program && program.sessions && program.sessions.length > 0) {
        const sortedSessions = program.sessions.slice().sort((s1, s2) => s1.startTime - s2.startTime);
        const purhaseDeadline = new Date(sortedSessions[0].startTime - 86400000);
        return new Date() < purhaseDeadline && !program.canceled;
    }
    return true;
};

export const getProgramActivePrice = (program: Program): ProgramPrice => {
    const now = Date.now();
    const prices = program.price
        .map((price, i) => {
            if (i === 1) {
                price.discountName = 'super early bird';
            } else if (i === 2) {
                price.discountName = 'early bird';
            }
            return price;
        })
        .filter((price) => price.deadline === undefined || now < price.deadline);
    prices.sort((p1, p2) => {
        if (!p1.deadline) {
            return 1;
        }
        if (!p2.deadline) {
            return -1;
        }
        return p1.deadline - p2.deadline;
    });
    return prices[0];
};

export const getProgramActivePriceBaseOnTime = (program: Program, time: number): ProgramPrice => {
    const prices = program.price
        .map((price, i) => {
            if (i === 1) {
                price.discountName = 'super early bird';
            } else if (i === 2) {
                price.discountName = 'early bird';
            }
            return price;
        })
        .filter((price) => price.deadline === undefined || time < price.deadline);
    prices.sort((p1, p2) => {
        if (!p1.deadline) {
            return 1;
        }
        if (!p2.deadline) {
            return -1;
        }
        return p1.deadline - p2.deadline;
    });
    return prices[0];
};
