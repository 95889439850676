export const DOMAIN_STAGE_MAP: { [key: string]: string } = {
    localhost: 'dev',
    'alpha.bloom.community': 'alpha',
    'beta.bloom.community': 'beta',
    'gamma.bloom.community': 'gamma',
    'bloom.community': 'prod',
    'hellobloomers.com': 'prod',
    'home.hellobloomers.com': 'prod',
    'www.alpha.bloom.community': 'alpha',
    'www.beta.bloom.community': 'beta',
    'www.gamma.bloom.community': 'gamma',
    'www.bloom.community': 'prod',
    'www.hellobloomers.com': 'prod',
    'www.home.hellobloomers.com': 'prod',
};

export const STAGE_DEFAULT_PROGRAM_MAP: { [key: string]: string } = {
    dev: 'p_8qy2fU2rV13LRyJoTXi1J2',
    alpha: 'p_8qy2fU2rV13LRyJoTXi1J2',
    beta: 'p_c33FzdQqjDNQbw1KTogH1Z',
    gamma: 'p_ucfxPtg7zSyHrLG3wTxbxd',
    prod: 'p_g9P15BQj2aGjwi96Bxr5ZN',
};

export const PROGRAM_PAYMENT_MAP: { [key: string]: string } = {
    BRT03: 'https://buy.stripe.com/28o7we8Zh8fbeWsdQY',
    BRT04: 'https://buy.stripe.com/7sIcQy6R93YVaGc8wF',
    BRT03_DISCOUNT: 'https://buy.stripe.com/3cs3fYdfxcvrbKg9AK',
    BRT04_DISCOUNT: 'https://buy.stripe.com/14k9Em6R9dzv5lS5kv',
    BRT05: 'https://buy.stripe.com/fZe7we7Vd7b701y9AQ',
    BRT06: 'https://buy.stripe.com/cN24k28Zh8fbg0w28n',
    BRT05_EARLY_BIRD: 'https://buy.stripe.com/eVa5o68Zh0MJ6pW14m',
    BRT06_EARLY_BIRD: 'https://buy.stripe.com/3cs2bU1wPdzv6pW5ky',
    BRT05_SUPER_EARLY_BIRD: 'https://buy.stripe.com/cN2g2K5N5dzv15C28p',
    BRT06_SUPER_EARLY_BIRD: 'https://buy.stripe.com/00gdUC2AT1QNeWs4gt',
};
