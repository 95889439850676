/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    AppBar,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Dialog,
    Grid,
    Hidden,
    Menu,
    MenuItem,
    Slide,
    Toolbar,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { lazy } from 'react';
import { AppStore } from '../../stores/appStore';
import { useStyles } from './style';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import { ReactComponent as LogoIcon } from '../../assets/logo.svg';
import { ReactComponent as MobileLogoIcon } from '../../assets/mobileLogo.svg';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { useHistory } from 'react-router-dom';
import { PATH } from '../../routes/path';
import { useEffect } from 'react';
import { COLORS } from '../../constant/colors';
import PROGRAM_TAGS from '../../constant/program';
const VideoPlayer = lazy(() => import('./videojsPlayer'));

interface HeaderMenuProps {
    appStore: AppStore;
    hideMenu?: boolean;
    backgroundColor?: string;
    hideOptions?: boolean;
    buttonHref?: string;
    buttonLabel?: string;
}

interface MenuItem {
    label: string;
    url?: string;
    onClick?: any;
    subItems?: MenuItem[];
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export const HeaderMenu: React.FC<HeaderMenuProps> = observer((props: HeaderMenuProps) => {
    const classes = useStyles();
    const history = useHistory();

    const { appStore, hideMenu, backgroundColor, hideOptions, buttonHref, buttonLabel } = props;
    const { loading, video } = appStore;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const subMenuOpen = Boolean(anchorEl);
    const [state, setState] = React.useState({
        open: false,
    });

    useEffect(() => {
        const hash = window.location.hash;
        window.location.hash = '';
        window.location.hash = hash;
    });

    const handleClickOpen = () => {
        setState({
            ...state,
            open: true,
        });
    };

    const handleProgramsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setState({
            ...state,
            open: false,
        });
    };

    const handleSubMenuClose = () => {
        setAnchorEl(null);
    };

    const menuItems: MenuItem[] = [
        {
            label: 'Programs',
            onClick: handleProgramsClick,
            subItems: [
                {
                    label: 'Pre-retirement',
                    onClick: () => {
                        window.location.href = PATH.PROGRAMS.replace(':tab?', 'overview').replace(
                            ':tag?',
                            PROGRAM_TAGS.PRE_RETIREMENT,
                        );
                    },
                },
                {
                    label: 'Post-retirement',
                    onClick: () => {
                        window.location.href = PATH.PROGRAMS.replace(':tab?', 'overview').replace(
                            ':tag?',
                            PROGRAM_TAGS.POST_RETIREMENT,
                        );
                    },
                },
            ],
        },
        {
            label: 'About',
            onClick: () => {
                history.push({ pathname: PATH.ABOUT });
                setState({
                    ...state,
                    open: false,
                });
            },
        },
        {
            label: 'FAQ',
            onClick: () => {
                history.push({ pathname: PATH.FAQ });
                setState({
                    ...state,
                    open: false,
                });
            },
        },
        {
            label: 'Blog',
            onClick: () => {
                window.location.href = 'https://blog.hellobloomers.com/';
            },
        },
    ];

    const menuItemOnClick = (menuItem: MenuItem) => {
        return menuItem.onClick
            ? menuItem.onClick
            : () => {
                  if (menuItem.url && menuItem.url.length > 0) {
                      window.location.href = menuItem.url;
                  }
              };
    };

    const renderDesktopMenuItem = (menuItem: MenuItem, index: number) => {
        let underLine = false;
        if (
            (menuItem.label === 'About' && window.location.href.includes('/about')) ||
            (menuItem.label === 'Program' && window.location.href.includes('/program/details')) ||
            (menuItem.label === 'FAQ' && window.location.href.includes('/faq'))
        ) {
            underLine = true;
        }
        return (
            <Box key={`menu-button-${index}`}>
                <Button className={classes.headerButton} color="inherit" onClick={menuItemOnClick(menuItem)}>
                    <Typography
                        variant="button"
                        className={classes.headerButtonText}
                        style={underLine ? { textDecoration: 'underline', textDecorationThickness: 2 } : {}}
                    >
                        {menuItem.label}
                    </Typography>
                    {menuItem.subItems && menuItem.subItems.length > 0 && <KeyboardArrowDownSharpIcon />}
                </Button>
                {menuItem.subItems && menuItem.subItems.length > 0 && (
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={subMenuOpen}
                        onClose={handleSubMenuClose}
                        keepMounted={false}
                        PaperProps={{
                            style: {
                                transform: 'translateY(40px)',
                                backgroundColor: COLORS.LIGHT_BLUE,
                            },
                        }}
                    >
                        {menuItem.subItems.map((subItem, i) => {
                            return (
                                <MenuItem key={`subItem-${i}`} onClick={menuItemOnClick(subItem)}>
                                    {subItem.label}
                                </MenuItem>
                            );
                        })}
                    </Menu>
                )}
            </Box>
        );
    };

    const renderMobileMenuItem = (menuItem: MenuItem, index: number) => {
        return (
            <Box key={`menu-button-${index}`}>
                <Button className={classes.mobileMenuButton} color="inherit" onClick={menuItemOnClick(menuItem)}>
                    <Typography variant="button" className={classes.mobileMenuButtonText}>
                        {menuItem.label}
                    </Typography>
                    {menuItem.subItems && menuItem.subItems.length > 0 && <KeyboardArrowDownSharpIcon />}
                </Button>
                {menuItem.subItems && menuItem.subItems.length > 0 && (
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={subMenuOpen}
                        onClose={handleSubMenuClose}
                        keepMounted={false}
                        PaperProps={{
                            style: {
                                transform: 'translateX(0px) translateY(50px)',
                                backgroundColor: '#fff',
                                color: COLORS.DARK_PURPLE,
                            },
                        }}
                    >
                        {menuItem.subItems.map((subItem, i) => {
                            return (
                                <MenuItem key={`subItem-${i}`} onClick={menuItemOnClick(subItem)}>
                                    {subItem.label}
                                </MenuItem>
                            );
                        })}
                    </Menu>
                )}
            </Box>
        );
    };

    return (
        <>
            <AppBar
                position="static"
                className={classes.headerBar}
                elevation={2}
                style={backgroundColor ? { backgroundColor: backgroundColor } : { backgroundColor: '#fff' }}
            >
                <Grid container spacing={0} className={classes.headerBarContainer}>
                    <Grid item xs={3} className={classes.headerLogoContainer}>
                        <Hidden xsDown>
                            <LogoIcon
                                className={classes.headerLogo}
                                onClick={() => !hideOptions && history.push({ pathname: PATH.HOME })}
                            />
                        </Hidden>
                        {!hideMenu && (
                            <Hidden smUp>
                                <Button color="inherit" onClick={handleClickOpen}>
                                    <MenuIcon className={classes.menuIcon} />
                                </Button>
                            </Hidden>
                        )}
                    </Grid>
                    {!hideMenu && (
                        <Grid item xs={9}>
                            <Toolbar className={classes.headerToolBar}>
                                <Hidden xsDown>
                                    {!hideOptions && menuItems.map((item, index) => renderDesktopMenuItem(item, index))}
                                    <Button
                                        href={buttonHref ? buttonHref : `${PATH.HOME}#start-journey`}
                                        style={{ backgroundColor: COLORS.TRANSPARENT }}
                                        className={`${classes.headerButton} ${classes.joinButton} ${classes.headerButtonText} ${classes.typeFromButton}`}
                                    >
                                        {buttonLabel ? buttonLabel : 'GET STARTED'}
                                    </Button>
                                </Hidden>
                            </Toolbar>
                        </Grid>
                    )}
                </Grid>
            </AppBar>
            <Dialog
                fullScreen
                open={state.open}
                onClose={handleClose}
                TransitionComponent={Transition}
                className={classes.menuDialog}
                PaperProps={{ className: classes.menuDialogPaper }}
            >
                <Box className={classes.mobileMenuTopBar}>
                    <MobileLogoIcon
                        className={classes.mobileMenuLogo}
                        onClick={() => !hideOptions && history.push({ pathname: PATH.HOME })}
                    />
                    <CloseIcon className={classes.mobileMenuCloseIcon} fontSize="large" onClick={handleClose} />
                </Box>
                <Box className={classes.mobileMenuContainer}>
                    {!hideOptions && menuItems.map((item, index) => renderMobileMenuItem(item, index))}
                    <Button
                        onClick={() => {
                            window.location.href = buttonHref ? buttonHref : `${PATH.HOME}#start-journey`;
                            handleClose();
                        }}
                        style={{ backgroundColor: COLORS.TRANSPARENT }}
                        className={`${classes.headerButton} ${classes.joinButton} ${classes.headerButtonText} ${classes.mobileTypeFromButton}`}
                    >
                        {buttonLabel ? buttonLabel : 'GET STARTED'}
                    </Button>
                </Box>
            </Dialog>
            <Dialog
                open={video.play && video.url.length > 0}
                fullWidth
                onClose={() => {
                    if (window.location.href.includes('#')) {
                        history.replace(window.location.pathname);
                    }
                    appStore.stopVideo();
                }}
            >
                <VideoPlayer sources={[{ src: video.url }]} />
            </Dialog>
            <Backdrop open={loading > 0} className={classes.backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
});
