/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
    Box,
    Button,
    Container,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { useStyles } from './style';
import React, { useEffect, useState } from 'react';
import { AppStore } from '../../stores/appStore';
import { Check, CheckCircleOutlineSharp } from '@material-ui/icons';
import MESSAGES from '../../constant/messages';
import PROGRAM_TAGS from '../../constant/program';
import { getWorkshopDateTimeDisplay } from '../../utils/program';
import { validateEmail, validatePhoneNumber } from '../../utils/tools';
import { PhoneNumberFormat } from '../phoneNumberInput';

interface AssessmentLeadGenerationProps {
    appStore: AppStore;
}

interface RegisterFormState {
    name: string;
    email: string;
    phone: string;
    dateTime: string;
    error: { [key: string]: string };
}

export const AssessmentLeadGeneration: React.FC<AssessmentLeadGenerationProps> = observer(
    (props: AssessmentLeadGenerationProps) => {
        const classes = useStyles();

        const { appStore } = props;
        const { programStore, assessmentStore } = appStore;
        const { email } = assessmentStore!;
        const { programs } = programStore!;
        const workshopPrograms = Object.values(programs).filter(
            (p) => p.tags && p.tags.includes(PROGRAM_TAGS.WORKSHOP),
        );
        let workshopTimestamps: number[] = [];
        if (workshopPrograms.length > 0 && workshopPrograms[0].sessions && workshopPrograms[0].sessions.length > 0) {
            workshopTimestamps = workshopPrograms[0].sessions.map((session) => {
                return session.startTime;
            });
        }
        const workshopOptions = workshopTimestamps.map((t) => getWorkshopDateTimeDisplay(t));
        workshopOptions.push('Notify me of future workshops');

        const [registerFormState, setRegisterFormState] = useState<RegisterFormState>({
            name: '',
            email: email ? email : '',
            phone: '',
            dateTime: '',
            error: {},
        });

        const [registered, setRegistered] = useState(false);

        useEffect(() => {
            programStore!.loadPrograms(appStore);
        }, []);

        const handleRegisterFormChange =
            (prop: keyof RegisterFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
                const error = { ...registerFormState.error };
                error[prop] = '';
                setRegisterFormState({ ...registerFormState, [prop]: event.target.value.trim(), error });
            };

        const handleClickRegisterNow = () => {
            let valid = true;
            const error: { [key: string]: string } = {};
            if (registerFormState.name === '') {
                error.name = MESSAGES.REQUIRED_FIELD;
                valid = false;
            }
            if (!validateEmail(registerFormState.email)) {
                error.email = MESSAGES.INVALID_EMAIL;
                valid = false;
            }
            if (registerFormState.email === '') {
                error.email = MESSAGES.REQUIRED_FIELD;
                valid = false;
            }
            if (!validatePhoneNumber(registerFormState.phone)) {
                error.phone = MESSAGES.INVALID_PHONE;
                valid = false;
            }
            if (registerFormState.phone === '') {
                error.phone = MESSAGES.REQUIRED_FIELD;
                valid = false;
            }
            if (registerFormState.dateTime === '') {
                error.dateTime = MESSAGES.REQUIRED_FIELD;
                valid = false;
            }
            if (!valid) {
                setRegisterFormState({ ...registerFormState, error: error });
            } else {
                programStore!.registerWorkshop({ ...registerFormState, error: undefined }, appStore, () => {
                    setRegistered(true);
                });
            }
        };

        return (
            <>
                <Grid spacing={2} container style={{ marginTop: 16, display: 'flex', alignItems: 'center' }}>
                    <Grid item xs={12} sm={10}>
                        <Typography variant="subtitle1">
                            <b>NEXT STEPS...</b> <br /> Develop your personalized retirement roadmap with a certified
                            retirement coach at Bloom. Join Anna Harvey (retirement coach) at one of our free workshops
                            to learn how to navigate your retirement transitions with confidence.
                        </Typography>
                    </Grid>
                    <Grid item sm={2} className={classes.mobileHide}>
                        <img src="/assets/annaHarvey.webp" alt="Anna Harvey" style={{ width: '100%' }} />
                    </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.purpleContainer}>
                    <Grid item xs={12} sm={5}>
                        <img src="/assets/laptop2.webp" alt="laptop" className={classes.fullWidth} />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Typography variant="h6">
                            <b>What you’ll learn</b>
                        </Typography>
                        <Box>
                            <Box className={`${classes.pointContainer}`}>
                                <Check className={classes.bannerIcon} />
                                <Typography variant="subtitle2" className={classes.pointText}>
                                    The 4 stages of retirement
                                </Typography>
                            </Box>
                            <Box className={`${classes.pointContainer}`}>
                                <Check className={classes.bannerIcon} />
                                <Typography variant="subtitle2" className={classes.pointText}>
                                    The 5 must-haves for a successful transition
                                </Typography>
                            </Box>
                            <Box className={`${classes.pointContainer}`}>
                                <Check className={classes.bannerIcon} />
                                <Typography variant="subtitle2" className={classes.pointText}>
                                    The difference between change and transition
                                </Typography>
                            </Box>
                            <Box className={`${classes.pointContainer}`}>
                                <Check className={classes.bannerIcon} />
                                <Typography variant="subtitle2" className={classes.pointText}>
                                    The value of vision and passion in creating your unique retirement plan
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box className={classes.registerFormContainer}>
                    {registered ? (
                        <Container maxWidth="md">
                            <CheckCircleOutlineSharp className={classes.secureMySpotIcon} />
                            <Typography variant="h5" id="register-now">
                                <b>You’re in!</b>
                            </Typography>
                            <Typography variant="subtitle1">
                                We’ll send you an email with the link to join on the day of. <br /> <br /> Looking
                                forward to seeing you soon!
                            </Typography>
                        </Container>
                    ) : (
                        <Box>
                            <Container maxWidth="xs">
                                <Typography variant="h4" id="register-now">
                                    <b>Register now</b>
                                </Typography>
                                <Typography variant="subtitle1" className={classes.marginTopMini}>
                                    Free Online Workshop: How to navigate your retirement transition with confidence
                                </Typography>
                                <FormControl className={classes.textField} variant="outlined">
                                    <InputLabel className={classes.fieldLabel}>First Name</InputLabel>
                                    <OutlinedInput
                                        type="text"
                                        className={classes.outlinedInput}
                                        value={registerFormState.name}
                                        onChange={handleRegisterFormChange('name')}
                                        label="First Name"
                                    />
                                    <FormHelperText error>{registerFormState.error.name}</FormHelperText>
                                </FormControl>
                                <FormControl className={classes.textField} variant="outlined">
                                    <InputLabel className={classes.fieldLabel}>Email</InputLabel>
                                    <OutlinedInput
                                        type="text"
                                        className={classes.outlinedInput}
                                        value={registerFormState.email}
                                        onChange={handleRegisterFormChange('email')}
                                        label="Email"
                                    />
                                    <FormHelperText error>{registerFormState.error.email}</FormHelperText>
                                </FormControl>
                                <FormControl className={classes.textField} variant="outlined">
                                    <InputLabel className={classes.fieldLabel}>
                                        Phone (receive a reminder text)
                                    </InputLabel>
                                    <OutlinedInput
                                        type="text"
                                        className={classes.outlinedInput}
                                        value={registerFormState.phone}
                                        onChange={handleRegisterFormChange('phone')}
                                        label="Phone (receive a reminder text)"
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        inputComponent={PhoneNumberFormat as any}
                                    />
                                    <FormHelperText error>{registerFormState.error.phone}</FormHelperText>
                                </FormControl>
                                <FormControl className={classes.textField} variant="outlined">
                                    <InputLabel className={classes.fieldLabel}>Select date/time</InputLabel>
                                    <Select
                                        style={{ textAlign: 'left' }}
                                        value={registerFormState.dateTime}
                                        className={classes.outlinedInput}
                                        onChange={(e) => {
                                            const error = { ...registerFormState.error };
                                            error.dateTime = '';
                                            setRegisterFormState({
                                                ...registerFormState,
                                                dateTime: e.target.value as string,
                                                error,
                                            });
                                        }}
                                        label="Select date/time"
                                    >
                                        {workshopOptions.map((value, i) => {
                                            return (
                                                <MenuItem key={`date-time-options-${i}`} value={value}>
                                                    {value}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    <FormHelperText error>{registerFormState.error.dateTime}</FormHelperText>
                                </FormControl>
                                <Button
                                    className={`${classes.purpleButtonFullWidth} ${classes.marginTopMid}`}
                                    onClick={() => handleClickRegisterNow()}
                                    id="workshop-signup"
                                >
                                    REGISTER NOW
                                </Button>
                            </Container>
                        </Box>
                    )}
                </Box>
            </>
        );
    },
);

export default AssessmentLeadGeneration;
