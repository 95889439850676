import { Box, Button, Container, SvgIcon, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './style';
import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/instagram.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/linkedIn.svg';
import { ReactComponent as TwitterIcon } from '../../assets/twitter.svg';

interface SocialMediaIcon {
    svg: React.FC;
    link: string;
    svgViewBox: string;
}

const socialMediaIcons: SocialMediaIcon[] = [
    {
        svg: FacebookIcon,
        link: 'https://www.facebook.com/HelloBloomers1',
        svgViewBox: '0 0 24 24',
    },
    {
        svg: TwitterIcon,
        link: 'https://twitter.com/hellobloomers',
        svgViewBox: '0 0 24 24',
    },
    {
        svg: InstagramIcon,
        link: 'https://www.instagram.com/hello.bloomers/',
        svgViewBox: '0 0 24 24',
    },
    {
        svg: LinkedInIcon,
        link: 'https://www.linkedin.com/company/hello-bloomers/',
        svgViewBox: '0 0 24 24',
    },
];

interface FooterProps {
    color?: string;
}

export const Footer: React.FC<FooterProps> = (props: FooterProps) => {
    const classes = useStyles();

    const { color } = props;

    const renderSocialMediaIcons = (socialMediaIcons: SocialMediaIcon[]) => {
        return (
            <Box className={classes.footerSocialMediaContainer}>
                {socialMediaIcons.map((icon: SocialMediaIcon, i: number) => {
                    return (
                        <Button
                            onClick={() => (window.location.href = icon.link)}
                            className={classes.footerSocialMediaButton}
                            key={i}
                        >
                            <SvgIcon
                                component={icon.svg}
                                className={classes.footerSocialMediaIcon}
                                viewBox={icon.svgViewBox}
                            />
                        </Button>
                    );
                })}
            </Box>
        );
    };

    return (
        <Box className={classes.whiteBox} style={color ? { backgroundColor: color } : {}}>
            <Container maxWidth="md">
                {renderSocialMediaIcons(socialMediaIcons)}
                <Box className={classes.footerLinkContainer}>
                    <a href="mailto:support@hellobloomers.com" className={classes.textDecorationNone}>
                        <Typography variant="subtitle1" className={classes.footerLink}>
                            support@hellobloomers.com
                        </Typography>
                    </a>
                    <a href="tel:+16474956511" className={classes.textDecorationNone}>
                        <Typography variant="subtitle1" className={classes.footerLink}>
                            647 . 495 . 6511
                        </Typography>
                    </a>
                </Box>
                <Typography variant="subtitle2">Copyright ©️ 2021 Bloom. All rights reserved.</Typography>
                <Typography variant="subtitle2">Made with 🖤 in Toronto</Typography>
            </Container>
        </Box>
    );
};
