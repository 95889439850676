import { observer } from 'mobx-react';
import Routes from './routes/routes';
import { AppStore } from './stores/appStore';
import { MuiThemeProvider, createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { COLORS } from './constant/colors';

export const theme = responsiveFontSizes(
    createTheme({
        palette: {
            primary: {
                light: COLORS.LIGHT_BLUE,
                main: COLORS.LIGHT_BLUE,
                dark: COLORS.LIGHT_BLUE,
                contrastText: COLORS.LIGHT_BLUE,
            },
            secondary: {
                light: COLORS.DARK_BLUE,
                main: COLORS.DARK_BLUE,
                dark: COLORS.DARK_BLUE,
                contrastText: COLORS.DARK_BLUE,
            },
        },
        typography: {
            button: {
                textTransform: 'none',
                fontSize: 16,
                textAlign: 'center',
            },
            fontFamily: 'Poppins',
        },
        overrides: {
            MuiSlider: {
                thumb: {
                    color: COLORS.DARK_YELLOW,
                    height: '16px !important',
                    width: '16px !important',
                    marginTop: '-5px !important',
                    marginLeft: '-6px !important',
                },
                track: {
                    color: COLORS.DARK_YELLOW,
                    height: '6px !important',
                    borderRadius: 3,
                },
                rail: {
                    color: COLORS.LIGHT_ORANGE,
                    height: '6px !important',
                    borderRadius: 3,
                },
            },
        },
    }),
);

const App: React.FC = observer(() => {
    return (
        <MuiThemeProvider theme={theme}>
            <Routes appStore={new AppStore()} />
        </MuiThemeProvider>
    );
});

export default App;
