/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { COLORS } from './colors';
import {
    Paper,
    Container,
    Box,
    Typography,
    Grid,
    Button,
    Slider,
    MuiThemeProvider,
    createTheme,
    responsiveFontSizes,
    Divider,
    Dialog,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    DialogTitle,
    DialogContent,
} from '@material-ui/core';
import AssessmentLeadGeneration from '../components/retriementAssessmentLeadGeneration';
import { AppStore } from '../stores/appStore';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ReactComponent as MyResultIcon } from '../assets/myResult.svg';
import { ReactComponent as AverageResultIcon } from '../assets/averageResult.svg';
import { round } from '../utils/math';
import MESSAGES from './messages';
import { CheckCircleOutlineSharp } from '@material-ui/icons';
import { useStyles } from './style';
import { getWorkshopDateTimeDisplay } from '../utils/program';
import PROGRAM_TAGS from './program';
import { validateEmail, validatePhoneNumber } from '../utils/tools';
import { PhoneNumberFormat } from '../components/phoneNumberInput';

export const theme = responsiveFontSizes(
    createTheme({
        palette: {
            primary: {
                light: COLORS.LIGHT_BLUE,
                main: COLORS.LIGHT_BLUE,
                dark: COLORS.LIGHT_BLUE,
                contrastText: COLORS.LIGHT_BLUE,
            },
            secondary: {
                light: COLORS.DARK_BLUE,
                main: COLORS.DARK_BLUE,
                dark: COLORS.DARK_BLUE,
                contrastText: COLORS.DARK_BLUE,
            },
        },
        typography: {
            button: {
                textTransform: 'none',
                fontSize: 16,
            },
            fontFamily: 'Poppins',
        },
        overrides: {
            MuiSlider: {
                thumb: {
                    color: COLORS.DARK_PURPLE,
                    height: '16px !important',
                    width: '16px !important',
                    marginTop: '-5px !important',
                    marginLeft: '-6px !important',
                },
                track: {
                    color: COLORS.MIDIUM_BLUE,
                    height: '6px !important',
                    borderRadius: 3,
                },
                rail: {
                    color: COLORS.MIDIUM_BLUE,
                    height: '6px !important',
                    borderRadius: 3,
                },
            },
        },
    }),
);

export interface AssessmentQuestion {
    title?: string;
    subTitle: string;
    description?: string;
    image: string;
    progress: string;
    progressNumber: number;
    options?: {
        value: number;
        icon: any;
        activeIcon: any;
        label?: string;
    }[];
    slider?: {
        max: number;
        min: number;
        step: number;
        maxLabel: string;
        minLabel: string;
    };
}

export interface AssessmentResult {
    threshold: number;
    resultComponment: React.FC<any>;
    assessmentData: { [key: string]: any };
}

export interface Assessment {
    slug: string;
    sem?: {
        semSlug: string;
        assessmentHeader: string;
    }[];
    startPage?: {
        title?: string;
        subTitle: string;
        description: string;
        secondDescription?: string;
        fields?: { label: string; key: string }[];
        image: string;
        startButtonText: string;
    };
    submissionForm?: {
        title?: string;
        submissionSubTitle?: string;
        submissionDescription?: string;
        submissionFields: { label: string; key: string; subtitle?: string; options?: string[]; placeholder?: string }[];
        submissionButton: string;
        submissionImage?: string;
    };
    questions: AssessmentQuestion[];
    results: AssessmentResult[];
    imagePosition?: string;
    oneColumnDesktop?: boolean;
}

const optionIconStyle = { fill: COLORS.DARK_PURPLE, width: 40, height: 40 };

const retirementProgramBanner = (
    <Box
        style={{
            width: '100%',
            backgroundColor: COLORS.DARK_BLUE,
            color: '#fff',
            marginTop: 120,
            paddingTop: 32,
            paddingBottom: 32,
        }}
    >
        <Container maxWidth="md">
            <Grid
                container
                style={{
                    paddingBottom: 24,
                    paddingTop: 24,
                    padding: 12,
                }}
                spacing={2}
            >
                <Grid item xs={12} sm={4}>
                    <img
                        src={`${origin}/assets/retirementProgram.webp`}
                        alt="retirement program"
                        style={{
                            width: '100%',
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={8}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="h5">
                        <b>Bloom’s Retirement Transition Program</b>
                    </Typography>
                    <Typography variant="subtitle2" style={{ marginTop: 12 }}>
                        The world's first comprehensive program designed to prepare you for a fulfilling retirement by
                        developing an action plan and vision for your retirement, with support from retirement coaches,
                        and an incredible likeminded network.
                    </Typography>
                    <Button
                        style={{
                            fontSize: 16,
                            paddingTop: 6,
                            paddingBottom: 6,
                            fontFamily: 'Poppins',
                            color: COLORS.DARK_PURPLE,
                            backgroundColor: '#fff',
                            paddingLeft: 12,
                            paddingRight: 12,
                            borderStyle: 'none',
                            letterSpacing: 2,
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            textAlign: 'center',
                            borderRadius: 0,
                            marginTop: 12,
                        }}
                        href={window.location.origin}
                    >
                        LEARN MORE
                    </Button>
                </Grid>
            </Grid>
        </Container>
    </Box>
);

interface ResultComponmentProps {
    appStore: AppStore;
    assessmentData: { [key: string]: any };
    data: { [key: string]: any };
}

interface RegisterFormState {
    name: string;
    email: string;
    phone: string;
    dateTime: string;
    error: { [key: string]: string };
}

const ResultComponment: React.FC<ResultComponmentProps> = observer((props: ResultComponmentProps) => {
    const classes = useStyles();

    const { appStore, assessmentData } = props;
    const { assessmentStore, programStore } = appStore;
    const { firstname, email } = assessmentStore!;
    const { programs } = programStore!;
    const workshopPrograms = Object.values(programs).filter((p) => p.tags && p.tags.includes(PROGRAM_TAGS.WORKSHOP));
    let workshopTimestamps: number[] = [];
    if (workshopPrograms.length > 0 && workshopPrograms[0].sessions && workshopPrograms[0].sessions.length > 0) {
        workshopTimestamps = workshopPrograms[0].sessions.map((session) => {
            return session.startTime;
        });
    }
    const workshopOptions = workshopTimestamps.map((t) => getWorkshopDateTimeDisplay(t));
    workshopOptions.push('Notify me of future workshops');

    const [registerFormState, setRegisterFormState] = useState<RegisterFormState>({
        name: '',
        email: email ? email : '',
        phone: '',
        dateTime: '',
        error: {},
    });

    const [registered, setRegistered] = useState(false);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        programStore!.loadPrograms(appStore);
    }, []);

    const handleRegisterFormChange =
        (prop: keyof RegisterFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
            const error = { ...registerFormState.error };
            error[prop] = '';
            setRegisterFormState({ ...registerFormState, [prop]: event.target.value.trim(), error });
        };

    const handleClickRegisterNow = () => {
        let valid = true;
        const error: { [key: string]: string } = {};
        if (registerFormState.name === '') {
            error.name = MESSAGES.REQUIRED_FIELD;
            valid = false;
        }
        if (!validateEmail(registerFormState.email)) {
            error.email = MESSAGES.INVALID_EMAIL;
            valid = false;
        }
        if (registerFormState.email === '') {
            error.email = MESSAGES.REQUIRED_FIELD;
            valid = false;
        }
        if (!validatePhoneNumber(registerFormState.phone)) {
            error.phone = MESSAGES.INVALID_PHONE;
            valid = false;
        }
        if (registerFormState.phone === '') {
            error.phone = MESSAGES.REQUIRED_FIELD;
            valid = false;
        }
        if (registerFormState.dateTime === '') {
            error.dateTime = MESSAGES.REQUIRED_FIELD;
            valid = false;
        }
        if (!valid) {
            setRegisterFormState({ ...registerFormState, error: error });
        } else {
            programStore!.registerWorkshop({ ...registerFormState, error: undefined }, appStore, () => {
                setRegistered(true);
            });
        }
    };

    return (
        <>
            <Container maxWidth="md">
                <Paper elevation={4} style={{ padding: 12, paddingTop: 48, paddingBottom: 48 }}>
                    <Container maxWidth="sm" style={{ padding: 0 }}>
                        <img
                            src={`${origin}/assets/assessmentResult1.webp`}
                            alt="assessment result"
                            style={{
                                width: '100%',
                            }}
                        />
                        <Box style={{ marginTop: 16 }}>
                            <Typography variant="h5">
                                You're in the <b>{assessmentData.name} stage</b>, {firstname ? `${firstname}!` : ''}
                            </Typography>
                            <Typography variant="subtitle1">
                                <br />
                                <b>THIS MEANS YOU CAN BENEFIT FROM...</b>
                                <br />
                                <ul>
                                    {assessmentData.points.map(
                                        (point: { highlight: string; content: string }, i: number) => {
                                            return (
                                                <Box key={`point-${i}`}>
                                                    <li>
                                                        <b>{point.highlight}</b> {point.content}
                                                    </li>
                                                    <br />
                                                </Box>
                                            );
                                        },
                                    )}
                                </ul>
                            </Typography>
                        </Box>
                        <AssessmentLeadGeneration appStore={props.appStore} />
                    </Container>
                </Paper>
            </Container>
            {retirementProgramBanner}
            <Dialog open={open} className={classes.registerFormDialog} onClose={() => setOpen(false)} fullWidth>
                {registered ? (
                    <>
                        <DialogTitle disableTypography>
                            <Typography variant="subtitle1" id="register-now">
                                <b>FREE WORKSHOP</b>
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <CheckCircleOutlineSharp className={classes.secureMySpotIcon} />
                            <Typography variant="h5" id="register-now">
                                <b>You’re in!</b>
                            </Typography>
                            <Typography variant="subtitle1">
                                We’ll send you an email with the link to join on the day of. <br /> <br /> Looking
                                forward to seeing you soon!
                            </Typography>
                        </DialogContent>
                    </>
                ) : (
                    <>
                        <DialogTitle disableTypography>
                            <Typography variant="subtitle1" id="register-now">
                                <b>FREE WORKSHOP</b>
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Typography variant="subtitle1" className={classes.marginTopMini}>
                                How to navigate your retirement transitions with confidence
                            </Typography>
                            <FormControl className={classes.textField} variant="outlined">
                                <InputLabel className={classes.fieldLabel}>First Name</InputLabel>
                                <OutlinedInput
                                    type="text"
                                    className={classes.outlinedInput}
                                    value={registerFormState.name}
                                    onChange={handleRegisterFormChange('name')}
                                    label="First Name"
                                />
                                <FormHelperText error>{registerFormState.error.name}</FormHelperText>
                            </FormControl>
                            <FormControl className={classes.textField} variant="outlined">
                                <InputLabel className={classes.fieldLabel}>Email</InputLabel>
                                <OutlinedInput
                                    type="text"
                                    className={classes.outlinedInput}
                                    value={registerFormState.email}
                                    onChange={handleRegisterFormChange('email')}
                                    label="Email"
                                />
                                <FormHelperText error>{registerFormState.error.email}</FormHelperText>
                            </FormControl>
                            <FormControl className={classes.textField} variant="outlined">
                                <InputLabel className={classes.fieldLabel}>Phone</InputLabel>
                                <OutlinedInput
                                    type="text"
                                    className={classes.outlinedInput}
                                    value={registerFormState.phone}
                                    onChange={handleRegisterFormChange('phone')}
                                    label="Phone"
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    inputComponent={PhoneNumberFormat as any}
                                />
                                <FormHelperText error>{registerFormState.error.phone}</FormHelperText>
                            </FormControl>
                            <FormControl className={classes.textField} variant="outlined">
                                <InputLabel className={classes.fieldLabel}>Select date/time</InputLabel>
                                <Select
                                    style={{ textAlign: 'left' }}
                                    value={registerFormState.dateTime}
                                    className={classes.outlinedInput}
                                    onChange={(e) => {
                                        const error = { ...registerFormState.error };
                                        error.dateTime = '';
                                        setRegisterFormState({
                                            ...registerFormState,
                                            dateTime: e.target.value as string,
                                            error,
                                        });
                                    }}
                                    label="Select date/time"
                                >
                                    {workshopOptions.map((value, i) => {
                                        return (
                                            <MenuItem key={`date-time-options-${i}`} value={value}>
                                                {value}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText error>{registerFormState.error.dateTime}</FormHelperText>
                            </FormControl>
                            <Button
                                className={`${classes.purpleButtonFullWidth} ${classes.marginTopMid}`}
                                onClick={() => handleClickRegisterNow()}
                                id="workshop-signup"
                            >
                                REGISTER NOW
                            </Button>
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </>
    );
});

const assessmentOptions = [
    {
        value: 1,
        icon: <StarBorderIcon fontSize="large" style={optionIconStyle} />,
        activeIcon: <StarIcon fontSize="large" style={optionIconStyle} />,
        label: 'Not true',
    },
    {
        value: 2,
        icon: <StarBorderIcon fontSize="large" style={optionIconStyle} />,
        activeIcon: <StarIcon fontSize="large" style={optionIconStyle} />,
    },
    {
        value: 3,
        icon: <StarBorderIcon fontSize="large" style={optionIconStyle} />,
        activeIcon: <StarIcon fontSize="large" style={optionIconStyle} />,
    },
    {
        value: 4,
        icon: <StarBorderIcon fontSize="large" style={optionIconStyle} />,
        activeIcon: <StarIcon fontSize="large" style={optionIconStyle} />,
    },
    {
        value: 5,
        icon: <StarBorderIcon fontSize="large" style={optionIconStyle} />,
        activeIcon: <StarIcon fontSize="large" style={optionIconStyle} />,
        label: 'Very true',
    },
];

export const retirementAssessment: Assessment = {
    slug: 'retirement',
    sem: [
        {
            semSlug: 'assessment-ready',
            assessmentHeader: 'Are you ready to make the most of your newfound 2,000 hours a year?',
        },
        {
            semSlug: 'assessment-meaning',
            assessmentHeader: 'Are you prepared to have a meaningful retirement?',
        },
        {
            semSlug: 'assessment-purpose',
            assessmentHeader: 'Are you prepared to have a purposeful retirement?',
        },
    ],
    questions: [
        {
            subTitle: 'I have many ideas for how I want to spend my time in retirement.',
            image: `${origin}/assets/assessment2.webp`,
            progress: '20%',
            progressNumber: 20,
            options: assessmentOptions,
        },
        {
            subTitle: 'I know how to make the most of my newfound 2,000 hours a year in retirement.',
            image: `${origin}/assets/assessment3.webp`,
            progress: '30%',
            progressNumber: 30,
            options: assessmentOptions,
        },
        {
            subTitle: "I'm confortable stepping out of my comfort zone and trying new things.",
            image: `${origin}/assets/assessment4.webp`,
            progress: '40%',
            progressNumber: 40,
            options: assessmentOptions,
        },
        {
            subTitle: 'I regularly engage in varied activities that feel meaningful and fulfilling.',
            image: `${origin}/assets/assessment5.webp`,
            progress: '50%',
            progressNumber: 50,
            options: assessmentOptions,
        },
        {
            subTitle: 'My personal identity is not closely tied with my job / work.',
            image: `${origin}/assets/assessment6.webp`,
            progress: '60%',
            progressNumber: 60,
            options: assessmentOptions,
        },
        {
            subTitle: 'Without my hobby or leisure interest, my life would be missing something very meaningful.',
            image: `${origin}/assets/assessment7.webp`,
            progress: '70%',
            progressNumber: 70,
            options: assessmentOptions,
        },
        {
            subTitle: 'I have a personal cause or calling that I pursue.',
            image: `${origin}/assets/assessment8.webp`,
            progress: '80%',
            progressNumber: 80,
            options: assessmentOptions,
        },
        {
            subTitle: "I've discussed my retirement vision with important people in my life",
            image: `${origin}/assets/assessment9.webp`,
            progress: '90%',
            progressNumber: 90,
            options: assessmentOptions,
        },
        {
            subTitle: "I'm satisfied with the relationships I have with the important people in my life.",
            image: `${origin}/assets/assessment10.webp`,
            progressNumber: 98,
            progress: 'Last Question',
            options: assessmentOptions,
        },
    ],
    results: [
        {
            threshold: 0,
            resultComponment: ResultComponment,
            assessmentData: {
                name: 'neutral',
                points: [
                    {
                        highlight: 'Shifting your focus:',
                        content:
                            'from “what’s not working”/what you’re missing, to what the good things are in your life that you want to continue doing or do more of.',
                    },
                    {
                        highlight: 'Connecting with others:',
                        content:
                            'we all need our support system and community. You’re likely experiencing the discomfort of not knowing what’s next. That’s normal!  Rather than try to escape it, stay present and get curious about your feelings.',
                    },
                ],
            },
        },
        {
            threshold: 20,
            resultComponment: ResultComponment,
            assessmentData: {
                name: 'reorientation',
                points: [
                    {
                        highlight: 'Taking time for self-reflection:',
                        content:
                            'You’re fully distanced from your previous work. It’s time to start answering  important questions like Who am I now? What is my purpose?',
                    },
                    {
                        highlight: 'Creating a vision for your retirement:',
                        content:
                            'Be kind to yourself and recognize change  can be  challenging. You’re ready to accept that you’ve left your previous chapter, and are ready for the next.',
                    },
                ],
            },
        },
        {
            threshold: 35,
            resultComponment: ResultComponment,
            assessmentData: {
                name: 'comfortable',
                points: [
                    {
                        highlight: 'Enjoying this phase of your life:',
                        content:
                            'You have a  new daily schedule , and have at least partially created a new identity. This landscape will become familiar territory. Maybe it’s more like this, Amin: You want to make the most of this new territory.',
                    },
                    {
                        highlight: 'Getting clarity about this ideal new lifestyle:',
                        content:
                            'You may be wondering if this is the best path for you. Use this stage to amp up the pleasure in your life. Continue asking yourself: What kinds of plans and choices do I need to make the rest of my life the best of my life?',
                    },
                ],
            },
        },
    ],
    // startPage: {
    //     subTitle: 'Are you prepared for your retirement?',
    //     description: 'Take the quiz and find out (takes 3 mins)',
    //     image: `${origin}/assets/assessment1.webp`,
    //     startButtonText: 'START',
    // },
    submissionForm: {
        submissionSubTitle: 'Ready to see your result?',
        // submissionDescription: 'Please enter your email address below so we can email you the results',
        submissionFields: [
            {
                label: 'First name',
                key: 'firstname',
            },
            {
                label: 'Email',
                key: 'email',
            },
        ],
        submissionButton: 'SEND THE RESULTS',
        submissionImage: `${origin}/assets/assessment11.webp`,
    },
};

const assessmentSlider = {
    max: 10,
    min: 0,
    step: 1,
    maxLabel: 'Very true',
    minLabel: 'Not true',
};

export const prePostProgramAssessmentQuestions = [
    {
        subTitle: 'I view retirement as an opportunity and believe it will be an exciting period of my life.',
        image: `${origin}/assets/assessment2.webp`,
        progress: '10%',
        progressNumber: 10,
        slider: assessmentSlider,
        resultDescription:
            'It’s important to view retirement as an opportunity for growth.  Research shows that mindset plays a significant role in determining life’s outcomes.',
    },
    {
        subTitle: 'I believe retirement is a time to try new things and take on new challenges.',
        image: `${origin}/assets/assessment3.webp`,
        progress: '15%',
        progressNumber: 15,
        slider: assessmentSlider,
        resultDescription:
            'Almost everything that you want lies just outside your comfort zone.  A ‘can do’ attitude goes a long way to challenging yourself to try new things and grow your resilience.',
    },
    {
        subTitle: 'I know my core values and how to use them to guide myself in making important decisions.',
        image: `${origin}/assets/assessment4.webp`,
        progress: '20%',
        progressNumber: 20,
        slider: assessmentSlider,
        resultDescription:
            'Knowing and being guided by your values in retirement gives you the courage to change outdated situations and behaviours, and inspires you to stay true to who you are or want to be.',
    },
    {
        subTitle: 'I define myself by more than just my work.',
        image: `${origin}/assets/assessment5.webp`,
        progress: '25%',
        progressNumber: 25,
        slider: assessmentSlider,
        resultDescription:
            'While this is solid advice for any time of life, it is particularly true in retirement in order to minimize anxiety, maximize life satisfaction, and expand your horizons.',
    },
    {
        subTitle: 'I don’t let the expectations and opinions of other people affect my decisions.',
        image: `${origin}/assets/assessment6.webp`,
        progress: '30%',
        progressNumber: 30,
        slider: assessmentSlider,
        resultDescription:
            'Retirement offers many opportunities for personal and lifestyle decision making.  By becoming familiar with your own uniqueness and motivations, you can move forward with confidence.',
    },
    {
        subTitle: 'I have let go of the things that prevent me from genuinely enjoying life.',
        image: `${origin}/assets/assessment7.webp`,
        progress: '35%',
        progressNumber: 35,
        slider: assessmentSlider,
        resultDescription:
            'Retirement is a time of increased change, and change creates opportunities. To take advantage of them, you must let go of what’s standing in the way.',
    },
    {
        subTitle: 'I surround myself with people who truly care and support me.',
        image: `${origin}/assets/assessment8.webp`,
        progress: '40%',
        progressNumber: 40,
        slider: assessmentSlider,
        resultDescription:
            "There is strong evidence that loneliness and social isolation put your health at risk.  In retirement it's important to engage in meaningful relationships that give you the opportunity to both give and receive.",
    },
    {
        subTitle: 'I have discussed my retirement vision and goals with the important people in my life.',
        image: `${origin}/assets/assessment9.webp`,
        progress: '45%',
        progressNumber: 45,
        slider: assessmentSlider,
        resultDescription:
            'Having conversations about your retirement vision and goals with the important people in your life provides important opportunities to clarify your own thinking and respond to their questions.',
    },
    {
        subTitle: 'I engage in regular conversations about retirement with people around me.',
        image: `${origin}/assets/assessment10.webp`,
        progress: '50%',
        progressNumber: 50,
        slider: assessmentSlider,
        resultDescription:
            'Conversations about retirement often include new ideas and information, concerns and pitfalls, and networking-building opportunities. Since there’s no one source with all retirement information, take every advantage of learning from others.',
    },
    {
        subTitle: 'It’s easy for me to come up with a list of activities I enjoy doing.',
        image: `${origin}/assets/assessment11.webp`,
        progress: '55%',
        progressNumber: 55,
        slider: assessmentSlider,
        resultDescription:
            'A retirement life that is haphazardly cobbled together will feel unsatisfying.  Purposefully identifying enjoyable activities provides you with focus, and opportunities for creativity and connection.',
    },
    {
        subTitle: 'I am able to visualize what I want my life to look and feel like in 3-5 years.',
        image: `${origin}/assets/assessment2.webp`,
        progress: '60%',
        progressNumber: 60,
        slider: assessmentSlider,
        resultDescription:
            'Research shows that mental imagery can dramatically improve the ability to take action.  By visualising a detailed and positive picture, you prime your brain to create the outcomes that you want in retirement.',
    },
    {
        subTitle: 'I have a plan for how to fill my time with meaningful activities in retirement.',
        image: `${origin}/assets/assessment3.webp`,
        progress: '65%',
        progressNumber: 65,
        slider: assessmentSlider,
        resultDescription:
            'Leisure is a fundamental human need and in retirement, it takes a more central role in life.  Building a leisure plan that includes the right pace and mix of meaningful activities fosters a sense of well-being.',
    },
    {
        subTitle: 'I have a personal cause or “calling” that I am or will be pursuing in retirement.',
        image: `${origin}/assets/assessment4.webp`,
        progress: '70%',
        progressNumber: 70,
        slider: assessmentSlider,
        resultDescription:
            'A fundamental success factor in retirement is to actively contribute an aspect of yourself to something personally meaningful while also creating a positive impact in the world.  This shift from self-focus to other-focus brings with it a vital sense of fulfillment.',
    },
    {
        subTitle: 'My retirement goals are aligned with my financial plan.',
        image: `${origin}/assets/assessment5.webp`,
        progress: '75%',
        progressNumber: 75,
        slider: assessmentSlider,
        resultDescription:
            'In retirement, you are transitioning to a renewed life with a renewed vision. That requires a renewed financial plan.  By aligning your life, vision and financial plan, you are able to worry less and enjoy more.',
    },
    {
        subTitle: 'I know my strengths and how to use them to bring my retirement vision to life.',
        image: `${origin}/assets/assessment6.webp`,
        progress: '80%',
        progressNumber: 80,
        slider: assessmentSlider,
        resultDescription:
            'It’s important to view retirement as a time to know and use your strengths. Research indicates most people don’t see all the ways their strengths can positively impact their life.',
    },
    {
        subTitle: 'I have taken time to translate my retirement vision into clear and well-defined goals.',
        image: `${origin}/assets/assessment7.webp`,
        progress: '85%',
        progressNumber: 85,
        slider: assessmentSlider,
        resultDescription:
            'Your inspirational, aspirational retirement vision must be translated into clearly articulated and actionable goals that can be prioritized to bring your vision to life.',
    },
    {
        subTitle: 'I want to pursue an active lifestyle in retirement.',
        image: `${origin}/assets/assessment8.webp`,
        progress: '90%',
        progressNumber: 90,
        slider: assessmentSlider,
        resultDescription:
            'Research indicates that by age 75, about one in three men and one in two women are sedentary. Participating in activities that keep mind and body sharp leads to greater well-being and satisfaction.',
    },
];

const ProgramResultComponment: React.FC<ResultComponmentProps> = observer((props: ResultComponmentProps) => {
    const { assessmentData, appStore } = props;
    const { assessmentStore } = appStore;
    const { answerSums, counts } = assessmentStore!;
    const { data } = props;
    const { answers } = data;
    return (
        <>
            <Container maxWidth="md">
                <Container
                    maxWidth="sm"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        color: COLORS.DARK_GREY,
                    }}
                >
                    <Typography variant="h4" style={{ textAlign: 'center' }}>
                        <b>Results for your {assessmentData.assessmentName} Assessment</b>
                    </Typography>
                    <img
                        src={`${origin}/assets/assessment13.webp`}
                        alt="assessment result"
                        style={{
                            width: '60%',
                        }}
                    />
                    <Typography variant="subtitle1">
                        <br />
                        Your personalized report indicates areas of preparedness and identifies gaps providing a
                        functional blueprint for your retirement planning and transition. Your Score reveals how
                        prepared you are right now for your retirement transition as compared to others who have taken
                        the assessment. These scores pinpoint which areas require the most focus in planning for a
                        fulfilling retirement.
                        <br />
                        <br />
                        Bloom's Retirement Transition Program will provide you a personal, practical, and relevant
                        self-portrait of your lifestyle needs and provides insights on transitioning from your current
                        lifestyle to your new retirement lifestyle.
                    </Typography>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',
                            justifyContent: 'flex-start',
                            width: '100%',
                        }}
                    >
                        <Typography variant="subtitle1">
                            <br /> <b>RESULTS BREAKDOWN:</b>
                        </Typography>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                marginTop: 12,
                            }}
                        >
                            <MyResultIcon style={{ marginRight: 6 }} />
                            <Typography variant="subtitle1">My results</Typography>
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <AverageResultIcon style={{ marginRight: 6 }} />
                            <Typography variant="subtitle1">Average results</Typography>
                        </Box>
                    </Box>
                    {answers.map((answer: number, index: number) => {
                        const average = round(
                            answerSums[index] && counts[index] ? answerSums[index] / counts[index] : 6.3,
                            2,
                        );
                        return (
                            <Box
                                key={`result-item-${index}`}
                                style={{ width: '100%', paddingTop: 12, paddingBottom: 12 }}
                            >
                                <Typography variant="subtitle1">
                                    {index + 1}. {prePostProgramAssessmentQuestions[index].subTitle}
                                </Typography>
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <Slider aria-label="Volume" value={answer} min={0} step={1} max={10} disabled />
                                    <Typography variant="subtitle1" style={{ marginLeft: 18, marginTop: 12 }}>
                                        {answer}
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <MuiThemeProvider theme={theme}>
                                        <Slider aria-label="Volume" value={average} min={0} max={10} disabled />
                                    </MuiThemeProvider>
                                    <Typography variant="subtitle1" style={{ marginLeft: 18, marginTop: 12 }}>
                                        {average}
                                    </Typography>
                                </Box>
                                <Typography variant="subtitle1">
                                    {prePostProgramAssessmentQuestions[index].resultDescription}
                                </Typography>
                                <Divider style={{ marginTop: 12 }} />
                            </Box>
                        );
                    })}
                </Container>
            </Container>
        </>
    );
});

export const preProgramAssessment: Assessment = {
    slug: 'pre-program',
    questions: prePostProgramAssessmentQuestions,
    results: [
        {
            threshold: 0,
            resultComponment: ProgramResultComponment,
            assessmentData: {
                assessmentName: 'Pre-Program',
            },
        },
    ],
    startPage: {
        subTitle: 'Pre-Program Assessment',
        description:
            'This assessment is a tool used to shed some light on your preparedness for a successful retirement, so please do your best to answer each question honestly and to the best of your ability.',
        secondDescription:
            'Your answers and results will be kept strictly confidential and not shared with anyone. It only takes 3 minutes to complete.',
        image: `${origin}/assets/assessment12.webp`,
        startButtonText: 'START',
        fields: [
            {
                label: 'email',
                key: 'email',
            },
        ],
    },
    submissionForm: {
        submissionFields: [
            {
                label: 'Year',
                key: 'bornIn',
                subtitle: 'What year were you born?',
                placeholder: 'example: 1955',
            },
            {
                label: 'Select one',
                key: 'relationshipStatus',
                subtitle: 'What is your relationship status?',
                options: ['', 'Single', 'Dating', 'Married', 'Widowed', 'Other'],
            },
            {
                label: 'Select one',
                key: 'joinReason',
                subtitle: 'Which of the following best describes your reason for joining the program?',
                options: [
                    '',
                    'I want to meet new people / form new friendships.',
                    'I want to learn how to navigate the transition into retirement.',
                    'Both',
                ],
            },
        ],
        submissionButton: 'NEXT',
    },
    imagePosition: 'top',
    oneColumnDesktop: true,
};

export const postProgramAssessment: Assessment = {
    slug: 'post-program',
    questions: prePostProgramAssessmentQuestions,
    results: [
        {
            threshold: 0,
            resultComponment: ProgramResultComponment,
            assessmentData: {
                assessmentName: 'Post-Program',
            },
        },
    ],
    startPage: {
        subTitle: 'Post-Program Assessment',
        description:
            'This assessment is a tool used to shed some light on your preparedness for a successful retirement, so please do your best to answer each question honestly and to the best of your ability.',
        secondDescription:
            'Your answers and results will be kept strictly confidential and not shared with anyone. It only takes 3 minutes to complete.',
        image: `${origin}/assets/assessment12.webp`,
        startButtonText: 'START',
        fields: [
            {
                label: 'email',
                key: 'email',
            },
        ],
    },
    imagePosition: 'top',
    oneColumnDesktop: true,
};

export const strengthAssessmentQuestions = [
    { question: 'I experience deep emotions when I see beautiful things.', key: 'apprOfBeautyExcl' },
    { question: 'I see beauty that other people pass by without noticing.', key: 'apprOfBeautyExcl' },
    { question: 'I am always aware of the natural beauty in the environment.', key: 'apprOfBeautyExcl' },
    { question: 'I always speak up in protest when I hear someone say mean things.', key: 'fairness' },
    { question: 'I am a brave person.', key: 'bravery' },
    { question: 'I am always coming up with new ways to do things. I have many interests.', key: 'loveOfLearning' },
    { question: 'I always treat people fairly whether I like them or not.', key: 'fairness' },
    { question: 'I do not want to see anyone suffer, even my worst enemy.', key: 'forgiveness' },
    { question: 'I am an extremely grateful person.', key: 'spirituality' },
    { question: 'I can accept love from others.', key: 'love' },
    { question: 'I am good at expressing love to someone else.', key: 'love' },
    { question: 'I often express my love to others.', key: 'love' },
    { question: 'I always make careful choices.', key: 'prudence' },
    { question: 'I always keep my promises.', key: 'leadership' },
    { question: 'I can always find the positive in what seems negative to others.', key: 'perspective' },
    { question: 'I try to add some humor to whatever I do.', key: 'humor' },
    { question: 'I always examine both sides of an issue.', key: 'perspective' },
    { question: 'Without exception, I support my teammates or fellow group members.', key: 'teamwork' },
    { question: 'I am never too busy to help a friend.', key: 'teamwork' },
    { question: 'I am always curious about the world.', key: 'curiosity' },
    { question: 'I am very good at leading group activities.', key: 'leadership' },
    { question: 'I always go out of my way to attend educational events.', key: 'loveOfLearning' },
    { question: 'I never brag about my accomplishments.', key: 'humility' },
    { question: 'I am a very careful person.', key: 'prudence' },
    { question: 'I never get sidetracked when I work.', key: 'selfRegulation' },
    { question: 'It is easy for me to stay disciplined.', key: 'selfRegulation' },
    { question: 'Even if I do not like someone, I treat him or her fairly.', key: 'fairness' },
    { question: 'My friends always tell me I am a strong but fair leader.', key: 'leadership' },
    { question: 'Others consider me to be a wise person.', key: 'socialIntelligence' },
    { question: 'I always get along well with people I have just met.', key: 'socialIntelligence' },
    { question: 'I believe it is best to forgive and forget.', key: 'forgiveness' },
    { question: 'I am a spiritual person.', key: 'spirituality' },
    { question: 'It is important to me to respect decisions made by my group.', key: 'judgment' },
    { question: 'I have lots of energy.', key: 'zest' },
    { question: 'It is important to me that I live in a world of beauty.', key: 'apprOfBeautyExcl' },
    { question: 'I pride myself on being original.', key: 'creativity' },
    { question: 'I think my life is extremely interesting.', key: 'curiosity' },
    { question: 'I rarely hold a grudge.', key: 'forgiveness' },
    { question: 'I have been richly blessed in my life.', key: 'gratitude' },
    { question: 'I believe honesty is the basis for trust.', key: 'honesty' },
    { question: 'If I feel down, I always think about what is good in my life.', key: 'gratitude' },
    { question: 'I make decisions only when I have all of the facts.', key: 'judgment' },
    { question: 'I go out of my way to cheer up people who appear down.', key: 'kindness' },
    { question: 'Despite challenges, I always remain hopeful about the future.', key: 'hope' },
    {
        question:
            'One of my strengths is helping a group of people work well together even when they have their differences.',
        key: 'leadership',
    },
    { question: 'I am a true life-long learner.', key: 'loveOfLearning' },
    { question: 'I prefer to let other people talk about themselves.', key: 'socialIntelligence' },
    { question: 'I always finish what I start.', key: 'perseverance' },
    { question: 'Others come to me for advice.', key: 'judgment' },
    { question: 'I am a highly disciplined person.', key: 'selfRegulation' },
    { question: 'I have the ability to make other people feel interesting.', key: 'socialIntelligence' },
    { question: 'I have a calling in my life.', key: 'perspective' },
    { question: 'I am known for my good sense of humor.', key: 'humor' },
    { question: 'I never allow a gloomy situation to take away my sense of humor.', key: 'humor' },
    { question: "I awaken with a sense of excitement about the day's possibilities.", key: 'hope' },
    { question: 'I have taken frequent stands in the face of strong opposition.', key: 'bravery' },
    { question: 'My friends say that I have lots of new and different ideas.', key: 'perspective' },
    { question: 'I am excited by many different activities.', key: 'creativity' },
    { question: 'I treat all people equally regardless of who they might be.', key: 'fairness' },
    { question: 'I am always willing to give someone a chance to make amends.', key: 'judgment' },
    { question: 'I feel a profound sense of appreciation every day.', key: 'gratitude' },
    { question: 'I tell the truth even if it means I will get in trouble.', key: 'honesty' },
    { question: 'I know that I will succeed with the goals I set for myself.', key: 'selfRegulation' },
    { question: 'I try to have good reasons for my important decisions.', key: 'judgment' },
    { question: 'I always try to help people in need.', key: 'kindness' },
    { question: 'I never miss the chance to learn something new.', key: 'curiosity' },
    { question: 'I have been told that modesty is one of my most notable characteristics.', key: 'humility' },
    { question: 'I never quit a task before it is done.', key: 'perseverance' },
    { question: 'My friends think I am wise.', key: 'prudence' },
    { question: 'I think through the consequences every time before I act.', key: 'judgment' },
    { question: 'I do not give into temptation.', key: 'prudence' },
    { question: 'I am good at sensing what other people are feeling.', key: 'socialIntelligence' },
    { question: 'My faith makes me who I am.', key: 'spirituality' },
    { question: 'I work at my very best when I am a group member.', key: 'teamwork' },
    { question: 'I am genuinely excited to start each day.', key: 'hope' },
    { question: 'I never hesitate to publicly express an unpopular opinion.', key: 'bravery' },
    { question: 'I am an original thinker.', key: 'creativity' },
    { question: 'I treat everyone the same.', key: 'fairness' },
    { question: 'At least once a day, I stop and count my blessings.', key: 'gratitude' },
    { question: 'My promises can be trusted.', key: 'honesty' },
    { question: 'I have a great sense of humor.', key: 'humor' },
    { question: 'When making a decision, I let the facts decide.', key: 'judgment' },
    { question: 'I really enjoy doing small favors for friends.', key: 'kindness' },
    { question: 'People usually turn to me to lead a group.', key: 'leadership' },
    { question: 'I love to learn new things. ', key: 'loveOfLearning' },
    { question: 'I am always humble about the good things that have happened to me.', key: 'humility' },
    { question: 'I finish things despite obstacles in the way.', key: 'perseverance' },
    { question: 'I may not say it to others, but I consider myself to be a wise person.', key: 'socialIntelligence' },
    { question: 'I always think before I speak.', key: 'judgment' },
    {
        question:
            'I never want things that are bad for me in the long run, even if they make me feel good in the short run.',
        key: 'prudence',
    },
    { question: 'I always know what to say to make people feel good.', key: 'socialIntelligence' },
    { question: 'My beliefs make my life important.', key: 'spirituality' },
    { question: 'I really enjoy being a part of a group.', key: 'teamwork' },
    { question: 'People describe me as full of zest.', key: 'zest' },
];

export const strengthAssessmentAttributes: { [key: string]: { label: string; description: string } } = {
    apprOfBeautyExcl: {
        label: 'Appreciation of beauty',
        description:
            'Noticing and appreciating beauty, excellence, and/or skilled performance in various domains of life, from nature to art to mathematics to science to everyday experience.',
    },
    bravery: {
        label: 'Bravery',
        description:
            'Not shrinking from threat, challenge, difficulty, or pain; speaking up for what’s right even if there’s opposition; acting on convictions even if unpopular; includes physical bravery but is not limited to it.',
    },
    love: {
        label: 'Love',
        description:
            'Valuing close relations with others, in particular those in which sharing & caring are reciprocated; being close to people.',
    },
    prudence: {
        label: 'Prudence',
        description:
            "Being careful about one's choices; not taking undue risks; not saying or doing things that might later be regretted.",
    },
    teamwork: {
        label: 'Teamwork',
        description: "Working well as a member of a group or team; being loyal to the group; doing one's share.",
    },
    creativity: {
        label: 'Creativity',
        description:
            'Thinking of novel and productive ways to conceptualize and do things; includes artistic achievement but is not limited to it.',
    },
    curiosity: {
        label: 'Curiosity',
        description:
            'Taking an interest in ongoing experience for its own sake; finding subjects and topics fascinating; exploring and discovering.',
    },
    fairness: {
        label: 'Fairness',
        description:
            'Treating all people the same according to notions of fairness and justice; not letting feelings bias decisions about others; giving everyone a fair chance.',
    },
    forgiveness: {
        label: 'Forgiveness',
        description:
            'Forgiving those who have done wrong; accepting others’ shortcomings; giving people a second chance; not being vengeful.',
    },
    gratitude: {
        label: 'Gratitude',
        description: 'Being aware of and thankful for the good things that happen; taking time to express thanks',
    },
    honesty: {
        label: 'Honesty',
        description:
            "Speaking the truth but more broadly presenting oneself in a genuine way and acting in a sincere way; being without pretense; taking responsibility for one's feelings and actions.",
    },
    hope: {
        label: 'Hope',
        description:
            'Expecting the best in the future and working to achieve it; believing that a good future is something that can be brought about.',
    },
    humor: {
        label: 'Humour',
        description:
            'Liking to laugh and tease; bringing smiles to other people; seeing the light side; making (not necessarily telling) jokes.',
    },
    perseverance: {
        label: 'Perseverance',
        description:
            'Finishing what one starts; persevering in a course of action in spite of obstacles; “getting it out the door”; taking pleasure in completing tasks.',
    },
    judgment: {
        label: 'Judgment',
        description:
            "Thinking things through and examining them from all sides; not jumping to conclusions; being able to change one's mind in light of evidence; weighing all evidence fairly.",
    },
    kindness: {
        label: 'Kindness',
        description: 'Doing favors and good deeds for others; helping them; taking care of them.',
    },
    leadership: {
        label: 'Leadership',
        description:
            'Encouraging a group of which one is a member to get things done and at the same time maintain good relations within the group; organizing group activities and seeing that they happen.',
    },
    loveOfLearning: {
        label: 'Love of Learning',
        description:
            "Mastering new skills, topics, and bodies of knowledge, whether on one's own or formally; related to the strength of curiosity but goes beyond it to describe the tendency to add systematically to what one knows.",
    },
    humility: {
        label: 'Humility',
        description:
            "Letting one's accomplishments speak for themselves; not regarding oneself as more special than one is.",
    },
    perspective: {
        label: 'Perspective',
        description:
            'Being able to provide wise counsel to others; having ways of looking at the world that make sense to oneself/others.',
    },
    selfRegulation: {
        label: 'Self-regulation',
        description: "Regulating what one feels and does; being disciplined; controlling one's appetites and emotions.",
    },
    socialIntelligence: {
        label: 'Social intelligence',
        description:
            'Being aware of the motives/feelings of others and oneself; knowing what to do to fit into different social situations; knowing what makes other people tick.',
    },
    spirituality: {
        label: 'Spirituality',
        description:
            'Having coherent beliefs about the higher purpose and meaning of the universe; knowing where one fits within the larger scheme; having beliefs about the meaning of life that shape conduct and provide comfort.',
    },
    zest: {
        label: 'Zest',
        description:
            'Approaching life with excitement and energy; not doing things halfway or halfheartedly; living life as an adventure; feeling alive and activated.',
    },
};

export const valueAssessmentAttributes = [
    {
        key: 'certainty',
        label: 'CERTAINTY',
        short: 'stability, orderliness, predictability',
        long: 'Stabilization allows you to feel comfortable and safe. People who value certainty do not like to be surprised and prefer a predictable lifestyle. The reality they create is their asylum and provides peace of mind.',
        icon: '/assets/valueIcon1.svg',
    },
    {
        key: 'control',
        label: 'CONTROL',
        short: 'control of the situation or environment',
        long: "People who value control, like to be masters of the situation. No matter what happens, they are ready to make decisions. They are also ready to take responsibility for their actions. Interest in control can arise from a person's need for security or ambition.",
        icon: '/assets/valueIcon2.svg',
    },
    {
        key: 'security',
        label: 'SECURITY',
        short: 'being free from danger or threat',
        long: 'The value of security comes from our primary needs. The sense of security allows you to feel comfortable and opens the possibility of further development. People in need of security can do a lot to avoid danger.',
        icon: '/assets/valueIcon3.svg',
    },
    {
        key: 'peace',
        label: 'PEACE',
        short: 'calm, freedom from conflict',
        long: 'People who value peace tend to avoid conflicts. If they engage in disputes, it is mainly to help resolve them. They are willing to make compromises and sacrifices if it is required to regain peace.',
        icon: '/assets/valueIcon4.svg',
    },
    {
        key: 'health',
        label: 'HEALTH',
        short: 'well-being, fitness, not being sick',
        long: 'Those who value health take conscious actions to maintain physical and mental fitness. They do not shy away from practicing sports and are happy to follow a healthy diet. They are aware of the consequences of insufficient health care.',
        icon: '/assets/valueIcon5.svg',
    },
    {
        key: 'discipline',
        label: 'DISCIPLINE',
        short: 'obedience, self-control',
        long: "It is the ability to subordinate one's actions to the realization of a long-term plan. Disciplined people always have a goal in mind. They are also aware of how much they can achieve with their work and persistence if they really want something.",
        icon: '/assets/valueIcon6.svg',
    },
    {
        key: 'jobSecurity',
        label: 'JOB SECURITY',
        short: 'not worrying about losing your job',
        long: 'Having a job gives you not only a salary, but also a certain social status. It also determines the role we play in society and our immediate surroundings. Employment security is for many people the foundation on which they build their lifestyle.',
        icon: '/assets/valueIcon7.svg',
    },
    {
        key: 'wealth',
        label: 'WEALTH',
        short: 'money, material goods, luxury lifestyle',
        long: 'Wealth can strongly affect the imagination. It allows us to feel comfortable, safe and exceptional. Although it does not guarantee finding true happiness, it gives a chance to discover the charms of life in any chosen way.',
        icon: '/assets/valueIcon8.svg',
    },
    {
        key: 'financialStability',
        label: 'FINANCIAL STABILITY',
        short: 'stable income, financial freedom',
        long: 'For people who value financial stability, money is important, but not the most important. It is a medium of exchange and is needed to fulfill your own needs and dreams. It is not an end in itself, but only an instrument needed to achieve it.',
        icon: '/assets/valueIcon9.svg',
    },
    {
        key: 'pleasure',
        label: 'PLEASURE',
        short: 'enjoyment, happiness, satisfaction',
        long: 'Everyone finds pleasure in something different. Indulging in pleasure allows you to break away from your daily routine. These are the moments when we can feel fulfilled. This is when we renew our energy for the next actions.',
        icon: '/assets/valueIcon10.svg',
    },
    {
        key: 'tradition',
        label: 'TRADITION',
        short: 'respecting customs, practicing rites',
        long: 'Recognition of tradition means respect for customs and rituals practiced by older generations. Attachment to tradition may result from the need for constancy or belonging. Tradition is often associated with the religion professed.',
        icon: '/assets/valueIcon11.svg',
    },
    {
        key: 'trust',
        label: 'TRUST',
        short: 'a belief in someone or something',
        long: "We all have to rely on something. Sometimes it's family and friends. Sometimes business partners or the government. Trust is a particularly important value because many aspects of our daily life are based on it.",
        icon: '/assets/valueIcon12.svg',
    },
    {
        key: 'privacy',
        label: 'PRIVACY',
        short: 'the right to have secrets, discretion',
        long: "In today's world, privacy is still a desired value. Privacy-conscious people only provide information that they want to share publicly. They feel uncomfortable about being watched.",
        icon: '/assets/valueIcon13.svg',
    },
    {
        key: 'accountability',
        label: 'ACCOUNTABILITY',
        short: 'responsibility, dependability',
        long: 'Responsibility is a much-appreciated value when talking about relationships with other people. It is important both personally and professionally. We feel safer around people we can rely on. Dependability is often the basis of trust.',
        icon: '/assets/valueIcon14.svg',
    },
    {
        key: 'challenge',
        label: 'CHALLENGE',
        short: 'testing your own abilities, competition',
        long: 'Taking up challenges is often the result of a strong need to test yourself. These are activities that require us to leave our comfort zone and face something new and unknown. They awaken our imagination and shape our character.',
        icon: '/assets/valueIcon15.svg',
    },
    {
        key: 'adventure',
        label: 'ADVENTURE',
        short: 'looking for exciting and risky activities',
        long: 'It is a passion for activities that cause excitement. Adventurers are not afraid to take risks. They are eager to explore and learn about the unknown. Their openness allows them to make new friends easily.',
        icon: '/assets/valueIcon16.svg',
    },
    {
        key: 'variety',
        label: 'VARIETY',
        short: 'frequent change, diversity',
        long: 'People who value diversity lead a very varied life, during which they will experience everything that caught their attention even for a moment. In the life of a person who loves diversity, there is only one thing you can be confident about - change.',
        icon: '/assets/valueIcon17.svg',
    },
    {
        key: 'excitment',
        label: 'EXCITEMENT',
        short: 'great enthusiasm, eagerness',
        long: 'Excitement and enthusiasm can attract people. People with such characteristics are often the spark that initiates many activities. There is always something they are working on or are planning to start. They are not afraid of taking up challenges and adventures.',
        icon: '/assets/valueIcon18.svg',
    },
    {
        key: 'courage',
        label: 'COURAGE',
        short: 'fearlessness, bravery',
        long: "It's about being ready to follow your heart and acting according to your values. Courageous people are seldom satisfied with compromises. They take what they want from life or are determined to find a way to achieve it.",
        icon: '/assets/valueIcon19.svg',
    },
    {
        key: 'curiosity',
        label: 'CURIOSITY',
        short: 'willingness to explore and learn',
        long: 'Curiosity is one of the traits that have had a huge impact on the shape of our civilization. Searching for answers to intriguing questions, in-depth knowledge of a subject, interest in the surrounding world are the characteristics of people who value curiosity.',
        icon: '/assets/valueIcon20.svg',
    },
    {
        key: 'creativity',
        label: 'CREATIVITY',
        short: 'imagination, inventiveness',
        long: 'The imagination of creative people has no limits. They shape the world as we know it. They are never template minded and are not afraid to question the status quo. Creating is their passion, and creativity manifests itself in many aspects of everyday life.',
        icon: '/assets/valueIcon21.svg',
    },
    {
        key: 'reputation',
        label: 'REPUTATION',
        short: "other people's opinion",
        long: 'Reputation reflects our value in the eyes of others. It has a great influence on how we are treated by our surroundings. It can take a lot of work and effort to achieve the desired reputation, but it will make it easier for us to achieve our goals in relationships with others.',
        icon: '/assets/valueIcon22.svg',
    },
    {
        key: 'respect',
        label: 'RESPECT',
        short: 'honour and care shown towards someone',
        long: "It is a recognition of someone's achievements or attitude. We respect those people who have had a strong and positive impact on our lives. We often adopt them as role models and are eager to learn from them.",
        icon: '/assets/valueIcon23.svg',
    },
    {
        key: 'authority',
        label: 'AUTHORITY',
        short: 'the right to lead and control',
        long: 'Power gives great opportunities, but also requires great responsibility. It gives privileges not available to other people. When used wisely, it allows you to achieve ambitious goals. In bad hands, it can turn out to be destructive.',
        icon: '/assets/valueIcon24.svg',
    },
    {
        key: 'fame',
        label: 'FAME',
        short: 'being known or recognized by many people',
        long: 'Fame is often desired by people who love to be the center of attention. The desire for fame can come from the need to be popular or the desire to achieve the benefits of recognition.',
        icon: '/assets/valueIcon25.svg',
    },
    {
        key: 'authenticity',
        label: 'AUTHENTICITY',
        short: 'truthfulness, genuineness',
        long: 'Authentic persons do not have to prove anything to others. They are themselves in every situation. When asked for their opinion, they will give an honest answer, even if their opinion is not popular. They do not find themselves in an environment where lying or lack of honesty often occurs.',
        icon: '/assets/valueIcon26.svg',
    },
    {
        key: 'beauty',
        label: 'BEAUTY',
        short: 'aesthetics, attractiveness',
        long: 'Seeing beauty in the world around us gives a lot of joy. Creating beautiful things is sharing this joy with others. People who appreciate beauty usually like to take care of their appearance. They also pay a lot of attention to the appearance of others.',
        icon: '/assets/valueIcon27.svg',
    },
    {
        key: 'appreciation',
        label: 'APPRECIATION',
        short: 'recognition of value or worth',
        long: "Appreciation is a significant respect for another person. Being appreciated always requires effort. Appreciation is given to people who have brought a lot of good in other people's lives or achieved something special with their own efforts.",
        icon: '/assets/valueIcon28.svg',
    },
    {
        key: 'acceptance',
        label: 'ACCEPTANCE',
        short: 'being included, approval',
        long: 'Acceptance is one of the most important human needs. It develops self-confidence and makes us feel that we mean something to others. The need for acceptance can have a strong impact on the decisions we make.',
        icon: '/assets/valueIcon29.svg',
    },
    {
        key: 'influence',
        label: 'INFLUENCE',
        short: 'having effect on people or things',
        long: 'Having influence allows you to shape the mindset of other people. It is a powerful ability and therefore requires a lot of responsibility. Influencers have a unique chance of doing something good for many people.',
        icon: '/assets/valueIcon30.svg',
    },
    {
        key: 'popularity',
        label: 'POPULARITY',
        short: 'being liked or admired by many people',
        long: 'Being popular is especially important for people who love being in the company of others. It makes you feel liked and important. Popularity makes it easier to use your contacts or make new friends.',
        icon: '/assets/valueIcon31.svg',
    },
    {
        key: 'uniqueness',
        label: 'UNIQUENESS',
        short: 'being particularly remarkable or unusual',
        long: 'Each of us is unique, but some people like to emphasize it. Extraordinary people make the world a much more interesting place. They like to be noticed and they like to surprise. They avoid everything that is common and popular in favor of what is unique and unusual.',
        icon: '/assets/valueIcon32.svg',
    },
    {
        key: 'love',
        label: 'LOVE',
        short: 'deep feeling of affection',
        long: 'Love manifests itself in the interest and care of another person. Depending on who it is addressed to, it can take various forms. It is one of the most important human needs because everyone wants to love and be loved.',
        icon: '/assets/valueIcon33.svg',
    },
    {
        key: 'family',
        label: 'FAMILY',
        short: 'caring for loved ones',
        long: 'Family is a value that meets many of our needs simultaneously. We find love, security, self-esteem and development in it. Taking care of the family we have a great opportunity to positively influence the lives of our loved ones. In return we are generously endowed with love.',
        icon: '/assets/valueIcon34.svg',
    },
    {
        key: 'honesty',
        label: 'HONESTY',
        short: 'sincerity, frankness',
        long: 'Honesty is about expressing your true opinion openly. It makes our thoughts and actions coherent. Honesty is based on authenticity, mutual trust and has a huge impact on shaping relationships with other people.',
        icon: '/assets/valueIcon35.svg',
    },
    {
        key: 'loyalty',
        label: 'LOYALTY',
        short: 'faithfulness',
        long: 'Loyalty is showing constant support to an individual or organization. Loyal people build their relationships based on trust. They conscientiously keep their commitment and expect the same from the other party.',
        icon: '/assets/valueIcon36.svg',
    },
    {
        key: 'forgiveness',
        label: 'FORGIVENESS',
        short: 'willingness to forgive others',
        long: 'At the heart of forgiveness lies a deep faith in the goodness of another person. People willing to forgive want to live in harmony. They understand well that we all make mistakes and believe that everyone deserves a second chance.',
        icon: '/assets/valueIcon37.svg',
    },
    {
        key: 'compassion',
        label: 'COMPASSION',
        short: 'empathy, sympathy',
        long: 'Compassion manifests itself in seeing and understanding the suffering of the other being. People capable of compassion are not indifferent to human problems. When possible, they voluntarily provide help and support.',
        icon: '/assets/valueIcon38.svg',
    },
    {
        key: 'friendship',
        label: 'FRIENDSHIP',
        short: 'comradeship, companionship',
        long: 'Friendship allows you to enjoy life in the company of kind people. It gives a sense of self-worth, acceptance and belonging. It teaches us to take care of the other person and share what we have to offer.',
        icon: '/assets/valueIcon39.svg',
    },
    {
        key: 'growth',
        label: 'GROWTH',
        short: 'physical, intellectual and spiritual development',
        long: "Growth is an integral part of every person's life. The power of development lies in the ability to independently decide on its direction. It allows us to achieve personal desires and become what we want to be.",
        icon: '/assets/valueIcon40.svg',
    },
    {
        key: 'passion',
        label: 'PASSION',
        short: 'a vocation or loved hobby',
        long: 'Passion is an activity in which we find real pleasure. People with passion are very excited when their interests are discussed. They can talk about them for hours. The effects of their work can be impressive because they put a lot of heart into it.',
        icon: '/assets/valueIcon41.svg',
    },
    {
        key: 'excellence',
        label: 'EXCELLENCE',
        short: 'mastery, perfection',
        long: 'Excellence is a path that only a few follow. Every day they push their limits to improve their workshop and craftsmanship. Good or very good results are not satisfactory enough for them. The improvement process is as important as the end result itself.',
        icon: '/assets/valueIcon42.svg',
    },
    {
        key: 'determination',
        label: 'DETERMINATION',
        short: 'persistence, perseverance',
        long: 'It is a constant effort despite numerous difficulties and adversities. Thanks to determination people fulfill their ambitions. It allows them to survive difficult moments and move on in the direction they want to go.',
        icon: '/assets/valueIcon43.svg',
    },
    {
        key: 'success',
        label: 'SUCCESS',
        short: 'achieving desired results',
        long: 'Success confirms that the right decisions were made and effectively implemented. It contributes to building self-esteem and confidence. Achieving success brings a lot of satisfaction and can be a source of pride.',
        icon: '/assets/valueIcon44.svg',
    },
    {
        key: 'independence',
        label: 'INDEPENDENCE',
        short: 'self-reliance, self-sufficiency',
        long: 'Independence gives a sense of security and control. It also creates favourable conditions for growth. Those who value independence are reluctant to give control over their life to someone else.',
        icon: '/assets/valueIcon45.svg',
    },
    {
        key: 'religion',
        label: 'RELIGION',
        short: 'devoutness, faith',
        long: 'Religion helps in finding the answer to the question about the meaning of life. Religious people often try to take an active part in the life of the local community centered around their religion, which allows them to experience a strong sense of belonging.',
        icon: '/assets/valueIcon46.svg',
    },
    {
        key: 'wisdom',
        label: 'WISDOM',
        short: 'making good decisions and judgements',
        long: 'Wisdom comes from the knowledge and experience gained in life. It allows to objectively assess the situation and make the right choices. It is also a mature way of looking at the world and other people.',
        icon: '/assets/valueIcon47.svg',
    },
    {
        key: 'intelligence',
        label: 'INTELLIGENCE',
        short: 'logical thinking, quick learning',
        long: 'Intelligent people are guided by logic when making decisions. They are able to assess the situation well and prefer to rely on their own opinions. They are careful with the ideas and advice given to them. Intelligence makes it easier to adapt quickly to a changing situation.',
        icon: '/assets/valueIcon48.svg',
    },
    {
        key: 'competence',
        label: 'COMPETENCE',
        short: 'capability, expertise, skill',
        long: 'Competence is a high level of knowledge and skills in a given field. Competent people put a lot of effort into ensuring that the quality of their work meets the highest expectations. They are aware of the need for continuous self-improvement.',
        icon: '/assets/valueIcon49.svg',
    },
    {
        key: 'spirituality',
        label: 'SPIRITUALITY',
        short: 'focusing on intangible aspects of life',
        long: 'Spirituality makes us focus a lot of our attention on immaterial reality. It is a constant search for the meaning of life. It evolves with our inner development and the subsequent stages of life.',
        icon: '/assets/valueIcon50.svg',
    },
    {
        key: 'innerHarmony',
        label: 'INNER HARMONY',
        short: 'balance, self-fulfilment',
        long: 'Internal harmony and balance allow you to find relief in the hustle and bustle of the surrounding world. Thanks to them, we can still enjoy small things. Self-awareness is an important element on the way to achieving inner balance.',
        icon: '/assets/valueIcon51.svg',
    },
    {
        key: 'ambition',
        label: 'AMBITION',
        short: 'aspiration, strong desire to succeed',
        long: 'Ambition comes from a strong self-esteem. It manifests itself in setting goals that are difficult to achieve and their consistent realization. Ambitious people build their image through their own achievements.',
        icon: '/assets/valueIcon52.svg',
    },
    {
        key: 'contribution',
        label: 'CONTRIBUTION',
        short: 'helping others to achieve a common goal',
        long: 'Making your own contribution gives satisfaction from participating in something important and needed by other people. It gives an opportunity to leave something valuable behind. It positively influences self-esteem and life satisfaction.',
        icon: '/assets/valueIcon53.svg',
    },
    {
        key: 'equality',
        label: 'EQUALITY',
        short: 'equal rights and opportunities for all',
        long: 'It is a belief that we all deserve equal rights and opportunities. Equality is to protect us from injustice and exclusion. Ensuring equality does not come easily and requires breaking down many barriers.',
        icon: '/assets/valueIcon54.svg',
    },
    {
        key: 'justice',
        label: 'JUSTICE',
        short: 'fairness, integrity',
        long: 'Social order is based on justice and honesty. Righteous people follow precisely defined principles in their lives. They expect the same from others. They generally have a very well-developed sense of morality.',
        icon: '/assets/valueIcon55.svg',
    },
    {
        key: 'meaningfulWork',
        label: 'MEANINGFUL WORK',
        short: "positive impact on other people's lives",
        long: 'Having a valuable job allows you to positively influence the lives of others. It gives a sense of being needed and useful to society. Seeing the value in your work gives you a lot of energy to act and avoids professional burnout.',
        icon: '/assets/valueIcon56.svg',
    },
    {
        key: 'teamwork',
        label: 'TEAMWORK',
        short: 'collaboration, synergy',
        long: 'Teamwork proves that more can be achieved by working with others than by acting alone. This is a key value for people working on very complex projects. Team problem solving allows you to achieve goals more efficiently.',
        icon: '/assets/valueIcon57.svg',
    },
    {
        key: 'tolerance',
        label: 'TOLERANCE',
        short: 'acceptance, openness, open-mindedness',
        long: 'It is respect for other people, regardless of their views and lifestyle. The basis of tolerance is awareness of your own worth. People with open minds are not afraid of being different, and see value in diversity.',
        icon: '/assets/valueIcon58.svg',
    },
    {
        key: 'commitment',
        label: 'COMMITMENT',
        short: 'engagement, support',
        long: 'Commitment and involvement are very important values in working with other people. They build the image of a good employee and allow you to gain trust and respect. We are most willing to engage in things that are close to us and in line with our values.',
        icon: '/assets/valueIcon59.svg',
    },
    {
        key: 'ethics',
        label: 'ETHICS',
        short: 'moral behavior',
        long: 'People who value ethics often stand guard over morality. They actively oppose unethical actions and are able to assess the situation well through a broader perspective. They are excellent at making objective judgments.',
        icon: '/assets/valueIcon60.svg',
    },
    {
        key: 'helpfulness',
        label: 'HELPFULNESS',
        short: 'benevolence, service, altruism',
        long: 'Altruists put other people’s needs above their own. They devote a large part of their lives to helping those in need. Thanks to their sensitivity and empathy they are not indifferent to the problems of others.',
        icon: '/assets/valueIcon61.svg',
    },
    {
        key: 'freedom',
        label: 'FREEDOM',
        short: 'liberty, freedom of action and thought',
        long: 'It is free will, which can freely manifest itself in actions taken and opinions expressed. People who value freedom want to live according to their own beliefs and principles. They do not like limitations and patterns.',
        icon: '/assets/valueIcon62.svg',
    },
];

export const assessments = [retirementAssessment, preProgramAssessment, postProgramAssessment];
