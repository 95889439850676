import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from '../../constant/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerBar: {
            flexGrow: 1,
            color: COLORS.DARK_GREY,
            paddingLeft: 24,
            paddingRight: 24,
            position: 'fixed',
            width: '100%',
            backgroundColor: COLORS.TRANSPARENT,
            zIndex: 100,
            [theme.breakpoints.down('xs')]: {
                height: 56,
                paddingLeft: 0,
                paddingRight: 0,
            },
            [theme.breakpoints.up('sm')]: {
                height: 64,
            },
        },
        headerButton: {
            margin: '10px',
            padding: '5px 15px 5px 15px',
            marginRight: 0,
            borderRadius: 0,
            '&:hover': {
                backgroundColor: COLORS.TRANSPARENT_DARK_HOVER,
            },
        },
        menuIcon: {
            color: COLORS.DARK_GREY,
        },
        headerButtonText: {
            fontWeight: 'bold',
        },
        headerLogo: {
            color: COLORS.DARK_GREY,
            width: '80%',
            maxWidth: '120px',
            minWidth: '80px',
            height: 50,
        },
        headerLogoContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignContent: 'center',
        },
        headerBarContainer: {
            alignItems: 'center',
            flexDirection: 'row',
        },
        headerToolBar: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignContent: 'center',
            padding: 0,
        },
        joinButton: {
            borderStyle: 'solid',
            borderWidth: 2,
            borderRadius: 0,
            '&:hover': {
                backgroundColor: COLORS.WHITE_BORDER_HOVER,
            },
            minWidth: 180,
            letterSpacing: 2,
        },
        menuDialog: {
            margin: '0px',
            overflowX: 'hidden',
            width: '100%',
            paddingLeft: '0px',
            paddingRight: '0px',
        },
        menuDialogPaper: {
            backgroundColor: COLORS.DARK_PURPLE,
            overflow: 'scroll',
        },
        mobileMenuTopBar: {
            color: '#fff',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        mobileMenuLogo: {
            color: '#fff',
            fill: '#fff',
            width: '25px',
            marginLeft: '12px',
        },
        mobileMenuCloseIcon: {
            marginRight: '10px',
        },
        mobileMenuContainer: {
            color: '#fff',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        mobileMenuButton: {
            marginBottom: '26px',
            borderRadius: 0,
            paddingRight: '20px',
            paddingLeft: '20px',
        },
        mobileMenuButtonText: {
            fontSize: '16px',
            fontWeight: 'bold',
        },
        backdrop: {
            zIndex: '10000 !important' as 'auto',
        },
        typeFromButton: {
            fontSize: 16,
            paddingTop: 6,
            paddingBottom: 6,
            fontFamily: 'Poppins',
            cursor: 'pointer',
        },
        mobileTypeFromButton: {
            fontSize: 16,
            paddingTop: 6,
            paddingBottom: 6,
            fontFamily: 'Poppins',
            color: '#fff',
            borderColor: '#fff',
            cursor: 'pointer',
        },
    }),
);
