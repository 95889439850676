import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import Amplify from 'aws-amplify';
import { COGNITO_CONFIG } from './config/cognito';
import { DOMAIN_STAGE_MAP } from './config/web';
import App from './app';
import { ASSESSMENT_API, PROGRAM_SERVICE_API, USER_SERVICE_API, WAITLIST_API } from './config/api';

const domainName: string = window.location.hostname;
const stage: string = DOMAIN_STAGE_MAP[domainName];

if (stage === 'prod') {
    const tagManagerArgs = {
        gtmId: 'GTM-NCMVX7F',
        auth: '9WxMdwRwajWoHCaIqVqfsg',
        preview: 'env-1',
    };
    TagManager.initialize(tagManagerArgs);
}

Amplify.configure({
    Auth: {
        UserPoolId: COGNITO_CONFIG[stage].userPoolId,
        ClientId: COGNITO_CONFIG[stage].clientId,
    },
    API: {
        endpoints: [
            {
                name: WAITLIST_API[stage].name,
                endpoint: WAITLIST_API[stage].endpoint,
                custom_header: async () => {
                    return {
                        'X-Api-Key': WAITLIST_API[stage].apiKey,
                    };
                },
            },
            {
                name: ASSESSMENT_API[stage].name,
                endpoint: ASSESSMENT_API[stage].endpoint,
                custom_header: async () => {
                    return {
                        'X-Api-Key': ASSESSMENT_API[stage].apiKey,
                    };
                },
            },
            {
                name: USER_SERVICE_API[stage].name,
                endpoint: USER_SERVICE_API[stage].endpoint,
                custom_header: async () => {
                    return {
                        'X-Api-Key': USER_SERVICE_API[stage].apiKey,
                    };
                },
            },
            {
                name: PROGRAM_SERVICE_API[stage].name,
                endpoint: PROGRAM_SERVICE_API[stage].endpoint,
                custom_header: async () => {
                    return {
                        'X-Api-Key': PROGRAM_SERVICE_API[stage].apiKey,
                    };
                },
            },
        ],
    },
});

ReactDOM.render(<App />, document.getElementById('root'));
