export const PATH = {
    HOME: '/',
    ABOUT: '/about',
    FAQ: '/faq',
    PROGRAM: '/program/:id',
    PROGRAMS: '/programs/:tab?/:tag?',
    PROGRAM_DETAILS: '/program/details/:id/:tab?',
    PROGRAM_APPLY: '/program/apply/:programId',
    NOT_FOUND: '/not-found',
    ASSESSMENT: '/assessment/:slug',
    STRENGTH_ASSESSMENT: '/assessment/strength',
    VALUE_ASSESSMENT: '/assessment/value',
    LANDING_WORKSHOP: '/landing/workshop',
    LANDING_QUIZ: '/landing/quiz',
    LANDING_PROGRAM: '/landing/program',
    WORKSHOP_ATTENTANCE: '/join-workshop',
    WORKSHOP_SURVEY: '/workshop-survey',
    //temp solution, to be removed
    PROGRAM_PAYMENT: '/pay/:id/:discount?',
    WORKSHOP: '/workshop',
    WORKSHOP_V2: '/workshop-v2',
    WORKSHOP_SUCCESS: '/workshop-success/:time/:email',
    PAYMENT_SUCCESS: '/payment-success',
};
