export const COGNITO_CONFIG: { [key: string]: { userPoolId: string; clientId: string } } = {
    dev: {
        userPoolId: 'us-east-1_FDKvwPqVl',
        clientId: '6bsjii8hf5b4kjodq2kob3d4ji',
    },
    alpha: {
        userPoolId: 'us-east-1_FDKvwPqVl',
        clientId: '6bsjii8hf5b4kjodq2kob3d4ji',
    },
    beta: {
        //TODO: update this
        userPoolId: 'us-east-1_FDKvwPqVl',
        clientId: '6bsjii8hf5b4kjodq2kob3d4ji',
    },
    gamma: {
        //TODO: update this
        userPoolId: 'us-east-1_FDKvwPqVl',
        clientId: '6bsjii8hf5b4kjodq2kob3d4ji',
    },
    prod: {
        //TODO: update this
        userPoolId: 'us-east-1_FDKvwPqVl',
        clientId: '6bsjii8hf5b4kjodq2kob3d4ji',
    },
};
