/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
    Box,
    Button,
    Container,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { observer } from 'mobx-react';
import { AppStore } from '../../stores/appStore';
import { useStyles } from './style';
import { HeaderMenu } from '../../components/headerMenuV2';
import React, { useEffect, useState } from 'react';
import { Footer } from '../../components/footer';
import { COLORS } from '../../constant/colors';
import { ReactComponent as ExperienceIcon } from '../../assets/experience.svg';
import { ReactComponent as GuideIcon } from '../../assets/guide.svg';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { ReactComponent as OnlineIcon } from '../../assets/online.svg';
import { ReactComponent as TimeIcon } from '../../assets/time.svg';
import { Check } from '@material-ui/icons';
import MESSAGES from '../../constant/messages';
import { CheckCircleOutlineSharp } from '@material-ui/icons';
import PROGRAM_TAGS from '../../constant/program';
import { getWorkshopDateDisplay, getWorkshopDateTimeDisplay, getWorkshopTimeDisplay } from '../../utils/program';
import { validateEmail, validatePhoneNumber } from '../../utils/tools';
import { PhoneNumberFormat } from '../../components/phoneNumberInput';
import { PATH } from '../../routes/path';
import { useHistory } from 'react-router-dom';

export const WORKSHOP_SEM_PAGES = [
    {
        slug: 'retirement-goals',
        subHeader: 'How to find your retirement goals with confidence.',
        subHeadline: 'Learn about the 5 stages of retirement and the 5 must-haves for setting your retirement goals.',
    },
    {
        slug: 'retirement-planning',
        subHeader: 'How to plan for your retirement with confidence.',
        subHeadline:
            'Learn about the 5 stages of retirement and the 5 must-haves for creating a successful retirement plan.',
    },
    {
        slug: 'getting-ready',
        subHeader: 'How to get ready for retirement with confidence.',
        subHeadline:
            'Learn about the 5 stages of retirement and the 5 must-haves for transitioning into a fulfilling retirement.',
    },
    {
        slug: 'retirement-ideas',
        subHeader: 'How to find meaningful retirement activities.',
        subHeadline:
            'Learn about the 5 stages of retirement and the 5 must-haves for finding fulfilling retirement activities.',
    },
    {
        slug: 'finding-meaning',
        subHeader: 'How to find meaning in retirement.',
        subHeadline: 'Learn about the 5 stages of retirement and the 5 must-haves for having a meaningful retirement.',
    },
    {
        slug: 'finding-purpose',
        subHeader: 'How to find purpose in retirement.',
        subHeadline: 'Learn about the 5 stages of retirement and the 5 must-haves for having a purposeful retirement.',
    },
];

interface RegisterFormState {
    name: string;
    email: string;
    phone: string;
    dateTime: string;
    error: { [key: string]: string };
}

interface WorkshopPageProps {
    appStore: AppStore;
    content: { [key: string]: string };
}

export const WorkshopPage: React.FC<WorkshopPageProps> = observer((props: WorkshopPageProps) => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    const { appStore, content } = props;
    const { programStore } = appStore;
    const { programs } = programStore!;
    const workshopPrograms = Object.values(programs).filter((p) => p.tags && p.tags.includes(PROGRAM_TAGS.WORKSHOP));
    let workshopTimestamps: number[] = [];
    if (workshopPrograms.length > 0 && workshopPrograms[0].sessions && workshopPrograms[0].sessions.length > 0) {
        workshopTimestamps = workshopPrograms[0].sessions.map((session) => {
            return session.startTime;
        });
    }
    const workshopOptions = workshopTimestamps.map((t) => getWorkshopDateTimeDisplay(t));
    workshopOptions.push('Notify me of future workshops');

    const [registerFormState, setRegisterFormState] = useState<RegisterFormState>({
        name: '',
        email: '',
        phone: '',
        dateTime: '',
        error: {},
    });

    const [registered, setRegistered] = useState(false);

    useEffect(() => {
        programStore!.loadPrograms(appStore);
    }, []);

    const handleRegisterFormChange =
        (prop: keyof RegisterFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
            const error = { ...registerFormState.error };
            error[prop] = '';
            setRegisterFormState({ ...registerFormState, [prop]: event.target.value.trim(), error });
        };

    const handleClickRegisterNow = () => {
        let valid = true;
        const error: { [key: string]: string } = {};
        if (registerFormState.name === '') {
            error.name = MESSAGES.REQUIRED_FIELD;
            valid = false;
        }
        if (!validateEmail(registerFormState.email)) {
            error.email = MESSAGES.INVALID_EMAIL;
            valid = false;
        }
        if (registerFormState.email === '') {
            error.email = MESSAGES.REQUIRED_FIELD;
            valid = false;
        }
        if (!validatePhoneNumber(registerFormState.phone)) {
            error.phone = MESSAGES.INVALID_PHONE;
            valid = false;
        }
        if (registerFormState.phone === '') {
            error.phone = MESSAGES.REQUIRED_FIELD;
            valid = false;
        }
        if (registerFormState.dateTime === '') {
            error.dateTime = MESSAGES.REQUIRED_FIELD;
            valid = false;
        }
        if (!valid) {
            setRegisterFormState({ ...registerFormState, error: error });
        } else {
            const workshopIndex = workshopOptions.indexOf(registerFormState.dateTime);
            programStore!.registerWorkshop({ ...registerFormState, error: undefined }, appStore, () => {
                history.push(
                    PATH.WORKSHOP_SUCCESS.replace(
                        ':time',
                        workshopTimestamps.length > workshopIndex && workshopIndex !== -1
                            ? workshopTimestamps[workshopIndex].toString()
                            : 'notify-fulture',
                    ).replace(':email', registerFormState.email),
                );
            });
        }
    };

    return (
        <>
            <Box className={classes.mainBox}>
                <HeaderMenu appStore={props.appStore} />
            </Box>
            <Box className={classes.lightBlueBox} style={{ paddingTop: 0 }}>
                <Box
                    className={classes.headerImage}
                    style={
                        mobile
                            ? {
                                  backgroundImage: `url(${window.location.origin}/assets/workshopHeaderImageMobile.webp)`,
                                  backgroundPosition: 'center',
                              }
                            : undefined
                    }
                >
                    <Container maxWidth="md" className={classes.headerContentContainer}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="h3" className={classes.marginTopMini}>
                                    Free Workshop:
                                </Typography>
                                <Typography variant="h3" className={classes.marginTopMini}>
                                    <b>
                                        {content.subHeader
                                            ? content.subHeader
                                            : 'How to navigate your retirement transitions with confidence'}
                                    </b>
                                </Typography>
                                <Typography variant="subtitle1" className={classes.marginTopMini}>
                                    {content.subHeadline
                                        ? content.subHeadline
                                        : 'Learn about the 5 stages of retirement and the 5 must-haves for a successful transition. Discover how creating a personalized retirement roadmap can make a difference.'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
                <Box className={classes.darkPurpleBanner}>
                    <Container maxWidth="md" className={classes.bannerContainer}>
                        <Typography variant="subtitle1">
                            <b>Next workshop starts: </b>
                        </Typography>
                        <Box className={classes.pointContainer}>
                            <CalendarIcon className={classes.bannerIcon} />
                            <Typography variant="subtitle2">
                                {workshopTimestamps.length > 0 ? getWorkshopDateDisplay(workshopTimestamps[0]) : ''}
                            </Typography>
                        </Box>
                        <Box className={classes.pointContainer}>
                            <TimeIcon className={classes.bannerIcon} />
                            <Typography variant="subtitle2">
                                {workshopTimestamps.length > 0 ? getWorkshopTimeDisplay(workshopTimestamps[0]) : ''}
                            </Typography>
                        </Box>
                        <Box className={classes.pointContainer}>
                            <OnlineIcon className={classes.bannerIcon} />
                            <Typography variant="subtitle2">Online</Typography>
                        </Box>
                        <Button href="#register-now" className={classes.whiteButton}>
                            REGISTER NOW
                        </Button>
                    </Container>
                </Box>
                <Box className={classes.lightOrangeBanner}>
                    <Container maxWidth="md">
                        <Typography variant="subtitle1">
                            Can’t make the above time?{' '}
                            <a href="#upcoming" className={classes.darkGrey}>
                                <b>View more upcoming workshops</b>
                            </a>
                        </Typography>
                    </Container>
                </Box>
                <LazyLoadComponent>
                    <Container maxWidth="md" className={classes.darkGrey}>
                        <Grid container spacing={4} className={classes.marginTopLarge}>
                            <Grid item xs={12} sm={6}>
                                <img src="/assets/workshop2.webp" alt="happy member" className={classes.fullWidth} />
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
                                <Typography variant="h4" className={classes.marginTopMini}>
                                    <b>A long and fulfilling retirement takes more than just money.</b>
                                </Typography>
                                <Typography variant="subtitle1" className={classes.marginTopMini}>
                                    Once you have a financial plan in place, it is essential to focus on all those
                                    things money cannot buy.
                                    <br /> <br />
                                    Discover how creating a <b>personalized retirement roadmap</b> can make a
                                    difference. Join Anna Harvey, Bloom’s Certified Retirement Coach, in one of our free
                                    workshops. Now’s the time to start navigating your retirement with confidence!
                                </Typography>
                                <Button
                                    href="#register-now"
                                    className={`${classes.purpleButton} ${classes.marginTopMini}`}
                                >
                                    REGISTER NOW
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </LazyLoadComponent>
            </Box>
            <Box className={classes.darkBlueBox}>
                <LazyLoadComponent>
                    <Container maxWidth="md">
                        <Typography variant="h4">
                            <b>What you’ll learn</b>
                        </Typography>
                        <Box className={classes.marginTopMid}>
                            <Box className={`${classes.pointContainer} ${classes.marginTopMini}`}>
                                <Check className={classes.bannerIcon} />
                                <Typography variant="subtitle1">
                                    The 4 stages of retirement, identify where you’re at, and how to move through each.
                                </Typography>
                            </Box>
                            <Box className={`${classes.pointContainer} ${classes.marginTopMini}`}>
                                <Check className={classes.bannerIcon} />
                                <Typography variant="subtitle1">
                                    Identify the 5 essential components for a healthy, happy and fulfilling retirement:
                                    status, structure, socialization, service and stability.
                                </Typography>
                            </Box>
                            <Box className={`${classes.pointContainer} ${classes.marginTopMini}`}>
                                <Check className={classes.bannerIcon} />
                                <Typography variant="subtitle1">
                                    The value of vision and passion in creating your unique retirement plan.
                                </Typography>
                            </Box>
                        </Box>
                        <Button
                            href="#register-now"
                            className={`${classes.whiteButtonXsFullWidth} ${classes.marginTopMid}`}
                        >
                            REGISTER FOR FREE
                        </Button>
                    </Container>
                </LazyLoadComponent>
            </Box>
            <Box className={classes.lightBlueBox} id="upcoming">
                <Container maxWidth="md">
                    <Typography variant="h4">
                        <b>Upcoming FREE workshops</b>
                    </Typography>
                    <Typography variant="subtitle1" className={classes.marginTopMini}>
                        The journey of a thousand miles begins with one step. Our upcoming free workshop can be the one
                        important step you take now toward creating a retirement you love to live. Our promise? Only 1
                        hour, and not 1000 miles!
                    </Typography>
                    <Grid container spacing={2} className={classes.upcomingContainer}>
                        {workshopTimestamps.map((workshop) => {
                            return (
                                <Grid item xs={12} sm={6} key={`workshop-${workshop}`}>
                                    <Paper elevation={6} className={classes.upcomingPaper}>
                                        <Typography variant="h6">
                                            <b>{getWorkshopDateDisplay(workshop)}</b>
                                        </Typography>
                                        <Box className={classes.pointContainer}>
                                            <TimeIcon className={classes.paperIcon} />
                                            <Typography variant="subtitle2">
                                                {getWorkshopTimeDisplay(workshop)}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.pointContainer}>
                                            <OnlineIcon className={classes.paperIcon} />
                                            <Typography variant="subtitle2">Online</Typography>
                                        </Box>
                                        <Button
                                            href="#register-now"
                                            className={`${classes.purpleButtonFullWidth} ${classes.marginTopMini}`}
                                        >
                                            SIGN UP
                                        </Button>
                                    </Paper>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Container>
            </Box>
            <Box className={classes.midBlueBox}>
                <Container maxWidth="md">
                    <Grid container spacing={1} className={classes.instructorMessageContainer}>
                        <Grid item xs={12} sm={9}>
                            <Typography variant="h4" className={classes.marginTopMini}>
                                <b>A message from your instructor:</b>
                            </Typography>
                            <Typography variant="subtitle1" className={classes.marginTopMini}>
                                Hi I’m Anna Harvey, a certified retirement Coach at Bloom. I’ve spent the last 10 years
                                helping hundreds of people just like you navigate a successful retirement transition.
                                Whether you're thinking about retirement or already retired and wondering “what’s next?”
                                this free workshop is for you!
                            </Typography>
                            <Box className={classes.marginTopMid}>
                                <Box className={classes.instructorPointContainer}>
                                    <ExperienceIcon className={classes.instructorPointIcon} />
                                    <Typography variant="subtitle2">10+ years experience</Typography>
                                </Box>
                                <Box className={classes.instructorPointContainer}>
                                    <GuideIcon className={classes.instructorPointIcon} />
                                    <Typography variant="subtitle2">Guided hundreds of retirees</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <img src="/assets/annaHarvey.webp" alt="Anna Harvey" className={classes.fullWidth} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box className={classes.darkPurpleBox} id="register-now">
                {registered ? (
                    <Container maxWidth="md">
                        <CheckCircleOutlineSharp className={classes.secureMySpotIcon} />
                        <Typography variant="h4">
                            <b>You’re in!</b>
                        </Typography>
                        <Typography variant="subtitle1">
                            We’ll send you an email with the link to join on the day of. <br /> <br /> Looking forward
                            to seeing you soon!
                        </Typography>
                    </Container>
                ) : (
                    <>
                        <Container maxWidth="md">
                            <Typography variant="h4">
                                <b>Register now</b>
                            </Typography>
                            <Typography variant="subtitle1">Free Online Workshop - Retirement Transition</Typography>
                        </Container>
                        <Container maxWidth="xs">
                            <FormControl className={classes.textField} variant="outlined">
                                <InputLabel className={classes.fieldLabel}>First Name</InputLabel>
                                <OutlinedInput
                                    type="text"
                                    className={classes.outlinedInput}
                                    value={registerFormState.name}
                                    onChange={handleRegisterFormChange('name')}
                                    label="First Name"
                                />
                                <FormHelperText error>{registerFormState.error.name}</FormHelperText>
                            </FormControl>
                            <FormControl className={classes.textField} variant="outlined">
                                <InputLabel className={classes.fieldLabel}>Email</InputLabel>
                                <OutlinedInput
                                    type="text"
                                    className={classes.outlinedInput}
                                    value={registerFormState.email}
                                    onChange={handleRegisterFormChange('email')}
                                    label="Email"
                                />
                                <FormHelperText error>{registerFormState.error.email}</FormHelperText>
                            </FormControl>
                            <FormControl className={classes.textField} variant="outlined">
                                <InputLabel className={classes.fieldLabel}>Phone (receive a reminder text)</InputLabel>
                                <OutlinedInput
                                    type="text"
                                    className={classes.outlinedInput}
                                    value={registerFormState.phone}
                                    onChange={handleRegisterFormChange('phone')}
                                    label="Phone (receive a reminder text)"
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    inputComponent={PhoneNumberFormat as any}
                                />
                                <FormHelperText error>{registerFormState.error.phone}</FormHelperText>
                            </FormControl>
                            <FormControl className={classes.textField} variant="outlined">
                                <InputLabel className={classes.fieldLabel}>Select date/time</InputLabel>
                                <Select
                                    style={{ textAlign: 'left' }}
                                    value={registerFormState.dateTime}
                                    className={classes.outlinedInput}
                                    onChange={(e) => {
                                        const error = { ...registerFormState.error };
                                        error.dateTime = '';
                                        setRegisterFormState({
                                            ...registerFormState,
                                            dateTime: e.target.value as string,
                                            error,
                                        });
                                    }}
                                    label="Select date/time"
                                >
                                    {workshopOptions.map((value, i) => {
                                        return (
                                            <MenuItem key={`date-time-options-${i}`} value={value}>
                                                {value}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText error>{registerFormState.error.dateTime}</FormHelperText>
                            </FormControl>
                            <Button
                                className={`${classes.whiteButtonFullWidth} ${classes.marginTopMid}`}
                                onClick={() => handleClickRegisterNow()}
                                id="workshop-signup"
                            >
                                REGISTER NOW
                            </Button>
                        </Container>
                    </>
                )}
            </Box>
            {/* <Box className={classes.lightBlueBox}>
                <LazyLoadComponent>
                    <Container maxWidth="md">
                        {recordingRegistered ? (
                            <>
                                <CheckCircleOutlineSharp className={classes.secureMySpotIcon} />
                                <Typography variant="h4" id="register-now">
                                    <b>Got it!</b>
                                </Typography>
                                <Typography variant="subtitle1">
                                    We’ll send you an email with the link to the recording soon!
                                </Typography>
                            </>
                        ) : (
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={5}>
                                    <img
                                        src="/assets/workshop3.webp"
                                        alt="happy member"
                                        className={classes.fullWidth}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={7} style={{ textAlign: 'left' }}>
                                    <Typography variant="h4">
                                        <b>Can’t make it?</b>
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        <b>Get a recording of the workshop:</b>
                                    </Typography>
                                    <FormControl className={classes.textFieldDark} variant="outlined">
                                        <InputLabel className={classes.fieldLabelDark}>Name</InputLabel>
                                        <OutlinedInput
                                            type="text"
                                            className={classes.outlinedInputDark}
                                            value={cantMakeItFormState.name}
                                            onChange={handleCantMakeItFormChange('name')}
                                            label="Name"
                                        />
                                        <FormHelperText error>{cantMakeItFormState.error.name}</FormHelperText>
                                    </FormControl>
                                    <FormControl className={classes.textFieldDark} variant="outlined">
                                        <InputLabel className={classes.fieldLabelDark}>Email</InputLabel>
                                        <OutlinedInput
                                            type="text"
                                            className={classes.outlinedInputDark}
                                            value={cantMakeItFormState.email}
                                            onChange={handleCantMakeItFormChange('email')}
                                            label="Email"
                                        />
                                        <FormHelperText error>{cantMakeItFormState.error.email}</FormHelperText>
                                    </FormControl>
                                    <Button
                                        className={`${classes.purpleButton} ${classes.marginTopMini}`}
                                        onClick={() => handleClickGetRecording()}
                                    >
                                        GET RECORDING
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Container>
                </LazyLoadComponent>
            </Box> */}
            <Footer color={COLORS.LIGHT_BLUE} />
        </>
    );
});

export default WorkshopPage;
