/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { API } from 'aws-amplify';
import { flow, makeObservable, observable } from 'mobx';
import PROGRAM_TAGS from '../constant/program';
import { Program, ProgramWaitlistItem } from '../models/program';
import { PATH } from '../routes/path';
import {
    getProgramActivePrice,
    getProgramWeekdays,
    getSessionTimeDisplay,
    getShortProgramDateRangeString,
    isPurchasableProgram,
    sortProgramSessions,
} from '../utils/program';
import { AppStore } from './appStore';

export const WORKSHOP_SURVEY = 'workshop survey';

export class ProgramStore {
    @observable program: Program | null;
    @observable programApplication: ProgramWaitlistItem | null;
    @observable waitlist: { [key: string]: string | number };
    @observable programs: { [key: string]: Program };
    @observable haveMoreDate = true;
    pageSize = 50;
    exclusiveStartKeys: string[] = [];

    constructor() {
        this.program = null;
        this.programApplication = null;
        this.waitlist = {};
        this.programs = {};
        makeObservable(this);
    }

    @flow
    loadProgram = function* (
        this: ProgramStore,
        programId: string,
        appStore: AppStore,
    ): Generator<Promise<Program>, void, Program> {
        appStore.loading++;
        try {
            const init = {
                queryStringParameters: {
                    programId,
                },
            };
            const response = yield API.get('program', '/prod/program/get', init).then((res) => res);
            const program = response as Program;
            if (!program) {
                window.location.pathname = PATH.NOT_FOUND;
            }
            sortProgramSessions(program);
            this.program = program;
            this.programs[program.programId!] = program;
        } catch (e) {
            console.log('Error', e);
            window.location.pathname = PATH.NOT_FOUND;
        }
        appStore.loading--;
    };

    @flow
    joinProgramContact = function* (
        this: ProgramStore,
        programId: string,
        data: any,
        appStore: AppStore,
        callBack?: () => void,
    ): Generator<Promise<void>, void, void> {
        appStore.loading++;
        try {
            const init = {
                body: {
                    ...data,
                    programId,
                },
            };
            yield API.post('waitlist', '/prod/join', init);
            if (callBack) {
                callBack();
            }
        } catch (e) {
            console.log('Error', e);
            window.location.pathname = PATH.NOT_FOUND;
        }
        appStore.loading--;
    };

    @flow
    registerWorkshop = function* (
        this: ProgramStore,
        data: any,
        appStore: AppStore,
        callBack?: () => void,
    ): Generator<Promise<void>, void, void> {
        appStore.loading++;
        try {
            const urlParams = window.location.search;
            const init = {
                body: {
                    ...data,
                    type: 'workshop sign up',
                    origin: urlParams.includes('gclid') ? 'SEM' : urlParams.includes('fbclid') ? 'Facebook' : undefined,
                },
            };
            yield API.post('waitlist', '/prod/join', init);
        } catch (e) {
            console.log('Error', e);
            window.location.pathname = PATH.NOT_FOUND;
        }
        if (callBack) {
            callBack();
        }
        appStore.loading--;
    };

    @flow
    cantMakeWorkshop = function* (
        this: ProgramStore,
        data: any,
        appStore: AppStore,
        callBack?: () => void,
    ): Generator<Promise<void>, void, void> {
        appStore.loading++;
        try {
            const urlParams = window.location.search;
            const init = {
                body: {
                    ...data,
                    type: 'workshop cant make it',
                    origin: urlParams.includes('gclid') ? 'SEM' : urlParams.includes('fbclid') ? 'Facebook' : undefined,
                },
            };
            yield API.post('waitlist', '/prod/join', init);
        } catch (e) {
            console.log('Error', e);
            window.location.pathname = PATH.NOT_FOUND;
        }
        if (callBack) {
            callBack();
        }
        appStore.loading--;
    };

    @flow
    submitWorkshopSurvey = function* (
        this: ProgramStore,
        data: any,
        appStore: AppStore,
        callBack?: () => void,
    ): Generator<Promise<{ [key: string]: string | number }>, void, { [key: string]: string | number }> {
        appStore.loading++;
        try {
            const waitlistDate = this.waitlist.id
                ? {
                      ...this.waitlist,
                      ...data,
                      type: WORKSHOP_SURVEY,
                  }
                : {
                      ...data,
                      type: WORKSHOP_SURVEY,
                  };
            const init = {
                body: waitlistDate,
            };
            this.waitlist = yield API.post('waitlist', '/prod/join', init);
            if (callBack) {
                callBack();
            }
        } catch (e) {
            console.log('Error', e);
            window.location.pathname = PATH.NOT_FOUND;
        }
        appStore.loading--;
    };

    @flow
    retrievesWorkshopSurvey = function* (
        this: ProgramStore,
        id: string,
        appStore: AppStore,
        callBack?: () => void,
    ): Generator<Promise<{ [key: string]: string | number }>, void, { [key: string]: string | number }> {
        appStore.loading++;
        try {
            const init = {
                queryStringParameters: {
                    id,
                },
            };
            this.waitlist = yield API.get('waitlist', '/prod/retrieve', init).then((res) => res);
            if (callBack) {
                callBack();
            }
        } catch (e) {
            console.log('Error', e);
            window.location.pathname = PATH.NOT_FOUND;
        }
        appStore.loading--;
    };

    @flow
    loadPrograms = function* (
        this: ProgramStore,
        appStore: AppStore,
        callBack?: () => void,
    ): Generator<unknown, void, unknown> {
        appStore.loading++;
        try {
            const init = {
                queryStringParameters: {
                    pageSize: this.pageSize,
                    lastEvaluatedKey:
                        this.exclusiveStartKeys.length > 0
                            ? this.exclusiveStartKeys[this.exclusiveStartKeys.length - 1]
                            : undefined,
                },
            };
            const data = yield API.get('program', '/prod/program/list', init).then((res) => res);
            const response = data as {
                programs: Program[];
                lastEvaluatedKey?: {
                    programId: string;
                };
            };
            if (response.lastEvaluatedKey) {
                this.exclusiveStartKeys.push(response.lastEvaluatedKey.programId);
                this.haveMoreDate = true;
            } else {
                this.haveMoreDate = false;
            }
            const programs = response.programs;
            programs.forEach((program) => {
                if (isPurchasableProgram(program)) {
                    sortProgramSessions(program);
                    this.programs[program.programId!] = program;
                }
            });
            if (callBack) {
                callBack();
            }
        } catch (e) {
            console.log('Error', e);
            window.location.pathname = PATH.NOT_FOUND;
        }
        appStore.loading--;
        return;
    };

    @flow
    joinProgramWaitlist = function* (
        this: ProgramStore,
        data: any,
        appStore: AppStore,
        callBack?: () => void,
    ): Generator<Promise<void>, void, void> {
        appStore.loading++;
        try {
            const init = {
                body: {
                    ...this.programApplication,
                    ...data,
                },
            };
            yield API.post('user', '/prod/waitlist/join', init);
            this.programApplication = {
                ...this.programApplication,
                ...data,
            };
            if (callBack) {
                callBack();
            }
        } catch (e) {
            console.log('Error', e);
            window.location.pathname = PATH.NOT_FOUND;
        }
        appStore.loading--;
    };

    @flow
    retrieveProgramWaitlist = function* (
        this: ProgramStore,
        programId: string,
        email: string,
        appStore: AppStore,
        callBack?: () => void,
    ): Generator<Promise<any[]>, void, any[]> {
        appStore.loading++;
        try {
            const init = {
                queryStringParameters: {
                    programId,
                    email,
                },
            };
            const response = yield API.get('user', '/prod/waitlist/retrieve', init).then((res) => res);
            if (response.length > 0) {
                this.programApplication = response[0];
            }
            if (callBack) {
                callBack();
            }
        } catch (e) {
            console.log('Error', e);
            window.location.pathname = PATH.NOT_FOUND;
        }
        appStore.loading--;
    };

    @flow
    workshopAttendanceCheck = function* (
        this: ProgramStore,
        email: string,
        attendedWorkshop: string,
        appStore: AppStore,
        callBack?: () => void,
    ): Generator<Promise<void>, void, void> {
        appStore.loading++;
        try {
            const init = {
                body: {
                    email,
                    attendedWorkshop,
                },
            };
            yield API.post('waitlist', '/prod/join', init);
        } catch (e) {
            console.log('Error', e);
            window.location.pathname = PATH.NOT_FOUND;
        }
        if (callBack) {
            callBack();
        }
        appStore.loading--;
    };

    @flow
    handleProgramPayment = function* (
        this: ProgramStore,
        program: Program,
        appStore: AppStore,
        callback: (url: string) => void,
    ): Generator<Promise<{ url: string }>, void, { url: string }> {
        appStore.loading++;
        try {
            let description = '';
            const activePrice = getProgramActivePrice(program);
            if (program.tags && program.tags.includes(PROGRAM_TAGS.FUTURE)) {
                description =
                    "Please submit payment to secure your spot in Bloom's upcoming Retirement Transition Program. We have already gone ahead applied our super early bird discount for you.";
            } else {
                description = `${getShortProgramDateRangeString(program)} | ${getProgramWeekdays(
                    program,
                )}${getSessionTimeDisplay(
                    program.sessions[0],
                )} | Please submit payment to secure your spot for the Retirement Transition Program.`;
                if (activePrice.discountName) {
                    description = `${description} We have already gone ahead applied our ${activePrice.discountName} discount for you.`;
                }
            }
            const init = {
                body: {
                    priceId: activePrice.stripePriceId,
                    successUrl: `${window.location.origin}/payment-success?p_id=${
                        this.programApplication!.programId
                    }&e=${this.programApplication!.email}&t=${Date.now()}`,
                    cancelUrl: window.location.href,
                    email: this.programApplication!.email,
                    programId: this.programApplication!.programId,
                    description,
                },
            };
            const response = yield API.post('program', '/prod/program/checkout', init).then((res) => res);
            callback(response.url);
        } catch (e) {
            console.log('Error', e);
            window.location.pathname = PATH.NOT_FOUND;
        }
        // appStore.loading--;
    };
}
