/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { API } from 'aws-amplify';
import { flow, makeObservable, observable } from 'mobx';
import { prePostProgramAssessmentQuestions } from '../constant/assessment';
import { PATH } from '../routes/path';
import { AppStore } from './appStore';

export class AssessmentStore {
    assessmentSubmissionId = '';
    @observable email = '';
    @observable firstname = '';
    @observable answerSums: number[] = [];
    @observable counts: number[] = [];

    constructor() {
        makeObservable(this);
    }

    @flow
    submitAssessment = function* (
        this: AssessmentStore,
        data: any,
        appStore: AppStore,
        callback?: () => void,
    ): Generator<Promise<any>, void, any> {
        appStore.loading++;
        try {
            const urlParams = window.location.search;
            const init = {
                body: {
                    ...data,
                    assessmentSubmissionId:
                        this.assessmentSubmissionId && this.assessmentSubmissionId !== ''
                            ? this.assessmentSubmissionId
                            : undefined,
                    origin: urlParams.includes('gclid') ? 'SEM' : urlParams.includes('fbclid') ? 'Facebook' : undefined,
                    statistic:
                        (data.slug === 'pre-program' && data.bornIn) ||
                        (data.slug === 'post-program' &&
                            data.answers &&
                            data.answers.length === prePostProgramAssessmentQuestions.length)
                            ? true
                            : undefined,
                },
            };
            const response = yield API.post('assessment', '/prod/submit', init).then((res) => res);
            if (
                response.assessmentSubmissionId &&
                !(
                    response.assessmentSubmissionId === 'pre-program' ||
                    response.assessmentSubmissionId === 'post-program'
                )
            ) {
                this.assessmentSubmissionId = response.assessmentSubmissionId;
            }
            if (data.email) {
                this.email = data.email;
            }
            if (data.firstname) {
                this.firstname = data.firstname;
            }
            if (response.answerSums && response.counts) {
                this.answerSums = response.answerSums;
                this.counts = response.counts;
            }
        } catch (e) {
            console.log('Error', e);
            window.location.pathname = PATH.NOT_FOUND;
        }
        if (callback) {
            callback();
        }
        appStore.loading--;
    };

    @flow
    retrieveAssessmentSubmission = function* (
        this: AssessmentStore,
        assessmentSubmissionId: string,
        appStore: AppStore,
        callback?: (data: { [key: string]: any }) => void,
    ): Generator<Promise<any>, void, any> {
        appStore.loading++;
        let response: { [key: string]: any } = {};
        try {
            const init = {
                queryStringParameters: {
                    as_id: assessmentSubmissionId,
                },
            };
            response = yield API.get('assessment', '/prod/retrieve', init).then((res) => res);
            if (!response.assessmentResult) {
                window.location.pathname = PATH.NOT_FOUND;
            }
            if (response.assessmentResult.email) {
                this.retrieveFirstName(response.assessmentResult.email, appStore);
            }
            if (response.statisticResults) {
                this.answerSums = response.statisticResults.answerSums;
                this.counts = response.statisticResults.counts;
            }
        } catch (e) {
            console.log('Error', e);
            window.location.pathname = PATH.NOT_FOUND;
        }
        if (callback) {
            callback({
                answers: response.assessmentResult.answers,
            });
        }
        appStore.loading--;
    };

    @flow
    retrieveFirstName = function* (
        this: AssessmentStore,
        email: string,
        appStore: AppStore,
    ): Generator<Promise<any>, void, any> {
        appStore.loading++;
        let response: { [key: string]: any } = {};
        try {
            const init = {
                queryStringParameters: {
                    email,
                },
            };
            response = yield API.get('user', '/prod/firstname', init).then((res) => res);
            if (response.firstname) {
                this.firstname = response.firstname;
            }
        } catch (e) {
            console.log('Error', e);
            window.location.pathname = PATH.NOT_FOUND;
        }
        appStore.loading--;
    };
}
