export const WAITLIST_API: { [key: string]: { name: string; endpoint: string; apiKey: string } } = {
    dev: {
        name: 'waitlist',
        endpoint: 'https://ysjp76zb1d.execute-api.us-east-1.amazonaws.com',
        apiKey: 'CGheeRsht075lIO1al63da5B5EnerHSS28zydsVP',
    },
    alpha: {
        name: 'waitlist',
        endpoint: 'https://ysjp76zb1d.execute-api.us-east-1.amazonaws.com',
        apiKey: 'CGheeRsht075lIO1al63da5B5EnerHSS28zydsVP',
    },
    beta: {
        name: 'waitlist',
        endpoint: 'https://c5zv4qmrzf.execute-api.us-east-1.amazonaws.com',
        apiKey: 'CNtxKY9cHU4qYjglWLzko6KUQC3Brvy865A6ZY8Z',
    },
    gamma: {
        name: 'waitlist',
        endpoint: 'https://du09eje9bh.execute-api.us-east-1.amazonaws.com',
        apiKey: 'QbV5mEKKd24sxFvoeMZP03KnrltsG2Ha6vNDVPTs',
    },
    prod: {
        name: 'waitlist',
        endpoint: 'https://lgy24l389l.execute-api.us-east-1.amazonaws.com',
        apiKey: '52rBt4Uelt6PKw5qcaNIK1Wdt2JSXXQf2qT5btrZ',
    },
};

export const ASSESSMENT_API: { [key: string]: { name: string; endpoint: string; apiKey: string } } = {
    dev: {
        name: 'assessment',
        endpoint: 'https://oiorogofjf.execute-api.us-east-1.amazonaws.com',
        apiKey: 'nHBzHOOmGo6ClO61FRWa9a6TEAqAtb9r5yqW4rBs',
    },
    alpha: {
        name: 'assessment',
        endpoint: 'https://oiorogofjf.execute-api.us-east-1.amazonaws.com',
        apiKey: 'nHBzHOOmGo6ClO61FRWa9a6TEAqAtb9r5yqW4rBs',
    },
    beta: {
        name: 'assessment',
        endpoint: 'https://4swds6a9hh.execute-api.us-east-1.amazonaws.com',
        apiKey: 'vUUkiUQiDZ4UJkLph9EzA7nKDks3PVZW9AZb5nFz',
    },
    gamma: {
        name: 'assessment',
        endpoint: 'https://mq24ip6dk3.execute-api.us-east-1.amazonaws.com',
        apiKey: '2wx7HccfeZaf9SrEJQmpM2VDNmZUMQvx8KkF6o6q',
    },
    prod: {
        name: 'assessment',
        endpoint: 'https://435dugm4xb.execute-api.us-east-1.amazonaws.com',
        apiKey: 'T3a9aZIBwW8haPGReQO4T6ZrY5IGjU7x9FMKcXCa',
    },
};

export const PROGRAM_SERVICE_API: { [key: string]: { name: string; endpoint: string; apiKey: string } } = {
    dev: {
        name: 'program',
        endpoint: 'https://1ogeosuo2c.execute-api.us-east-1.amazonaws.com',
        apiKey: '5pSDJTtndZ4cTnCuQ6vOr4FaPcm3sD15VjemE8V8',
    },
    alpha: {
        name: 'program',
        endpoint: 'https://1ogeosuo2c.execute-api.us-east-1.amazonaws.com',
        apiKey: '5pSDJTtndZ4cTnCuQ6vOr4FaPcm3sD15VjemE8V8',
    },
    beta: {
        name: 'program',
        endpoint: 'https://l0vzdr55ga.execute-api.us-east-1.amazonaws.com',
        apiKey: 'KMVjiN1u4q6BjKUyP36zj95mc8lCWPkiUqolqBf3',
    },
    gamma: {
        name: 'program',
        endpoint: 'https://pnx7gz68lb.execute-api.us-east-1.amazonaws.com',
        apiKey: 'xMTCHy8uJp4gDs7yCqCl0apyFoljUQ1F64yJlTxm',
    },
    prod: {
        name: 'program',
        endpoint: 'https://zvli8gwuv7.execute-api.us-east-1.amazonaws.com',
        apiKey: 'vkAXeQzcg03P1VYe36vAT5wYqBGiIO0v3m0ydH9B',
    },
};

export const USER_SERVICE_API: { [key: string]: { name: string; endpoint: string; apiKey: string } } = {
    dev: {
        name: 'user',
        endpoint: 'https://k6rmeyqrmc.execute-api.us-east-1.amazonaws.com',
        apiKey: '9VUGdJtWDhN0AxMZY0gu6ISIc4XL7wPyqg1y6Sg0',
    },
    alpha: {
        name: 'user',
        endpoint: 'https://k6rmeyqrmc.execute-api.us-east-1.amazonaws.com',
        apiKey: '9VUGdJtWDhN0AxMZY0gu6ISIc4XL7wPyqg1y6Sg0',
    },
    beta: {
        name: 'user',
        endpoint: 'https://zuz49hgay7.execute-api.us-east-1.amazonaws.com',
        apiKey: 'aYk4p0mAZHac4Ug87hXEC48uZ30ZeDN99mEMsZrx',
    },
    gamma: {
        name: 'user',
        endpoint: 'https://uighlyz9u0.execute-api.us-east-1.amazonaws.com',
        apiKey: 'hbIdNt6BhB55nC1PZ6XJ43IBoXirv8h54hvMoSey',
    },
    prod: {
        name: 'user',
        endpoint: 'https://q6zqjc98mk.execute-api.us-east-1.amazonaws.com',
        apiKey: 'WEqmXGF9v44ksS1lGfyJM1SqYacA04Rv5xtB5syN',
    },
};
